import { React } from "react";
import { Component } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  defaultShortlinkSettings,
  addAboutme,
  addSocialLinks,
  getUserImageLibrary,
  deleteUserImage,
} from "./../../../config/shortlink_api_calls";

import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import HelperClass from "./../../../config/helperClass";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { APP_LIVE_URL } from "./../../../config/config";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import PageTitle from "../ExtraComponents/PageTitle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MutextField from "@material-ui/core/TextField";
import { Editor } from "@tinymce/tinymce-react";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const auth = new HelperClass();

class SharelinkSettings extends Component {
  constructor() {
    super();
    this.state = {
      serviceData: [],
      about_me_short: "",
      about_me: "",
      errorMessage: "",
      instagram_link: "https://www.instagram.com/",
      facebook_link: "https://www.facebook.com/",
      twitter_link: "https://twitter.com/",
      linkedin_link: "https://www.linkedin.com/",
      youtube_link: "https://www.youtube.com/",
      successMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      logo: "",
      setting_id: "",
      disabled: false,
      errorMessageEdit: "",
      successMessageEdit: "",
      images_status: "1",
      images_type: "logo",
      picture: "",
      pictureName: "",
      image_title: "",
      logo_view: "",
      imageData: [],
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const servicesResponce = await defaultShortlinkSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Settings Data: ", servicesResponce.data.data[0]);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        about_me_short: servicesResponce.data.data[0]["about_me_short"],
        about_me: servicesResponce.data.data[0]["about_me"],
        instagram_link: servicesResponce.data.data[0]["instagram_link"],
        facebook_link: servicesResponce.data.data[0]["facebook_link"],
        twitter_link: servicesResponce.data.data[0]["twitter_link"],
        linkedin_link: servicesResponce.data.data[0]["linkedin_link"],
        youtube_link: servicesResponce.data.data[0]["youtube_link"],
        setting_id: servicesResponce.data.data[0]["id"],
      });
    } else {
      //window.location.replace("/error");
    }
  }

  getImageLibrary = async () => {
    this.setState({
      tableLoaderListing: true,
    });
    const servicesResponce = await getUserImageLibrary(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("getImageLibrary: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        imageData: servicesResponce.data.data,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  };

  formSave = async () => {
    window.scrollTo(0, 0);
    const { about_me, about_me_short } = this.state;

    const saveResponce = await addAboutme(
      auth.getAccount(),
      auth.getToken(),
      about_me,
      about_me_short
    );
    console.log("ADD RES: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "About me details added successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        errorMessage: saveResponce.data.message,
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  formSaveSocial = async () => {
    window.scrollTo(0, 0);
    const {
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link,
    } = this.state;

    const saveResponce = await addSocialLinks(
      auth.getAccount(),
      auth.getToken(),
      instagram_link,
      facebook_link,
      twitter_link,
      linkedin_link,
      youtube_link
    );
    console.log("ADD Social: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Social media links added successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        errorMessage: saveResponce.data.message,
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  formAddImage = async (e) => {
    e.preventDefault();
    const { images_type, images_status } = this.state;
    if (this.state.fileName !== "") {
      console.log("image: ", this.state.file);
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", this.state.picture);
      formData.append("image_type", this.state.images_type);
      formData.append("accountno", auth.getAccount());
      formData.append("fileName", this.state.pictureName);
      formData.append("status", this.state.images_status);
      formData.append("image_title", this.state.image_title);

      axios
        .post(
          process.env.REACT_APP_API_URL + "uploads/upload_user_image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((res) => {
          console.log(
            "File Upload RES " + this.state.images_type + ": ",
            res.data
          );
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            auth.logout();
          } else if (res.data.status === 404) {
            this.setState({
              successMessageEdit: "",
              errorMessageEdit: "There is some error while adding the image.",
            });
            //window.location.replace("/error");
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            this.setState({
              successMessageEdit: "Image added successfully.",
              errorMessageEdit: "",
              pictureName: "",
              picture: "",
              logo_view: res.data.data,
              signature_view: "",
            });
            setTimeout(() => {
              window.$("#modalAddImage").modal("hide");
              this.getImageLibrary();
            }, 3000);
          } else {
            this.setState({
              successMessageEdit: "",
              errorMessageEdit: "There is some error while adding the image.",
            });
          }
        });
    } else {
      this.setState({
        successMessageEdit: "",
        errorMessageEdit: "Please select an image to upload.",
      });
    }
    setTimeout(() => {
      this.setState({
        errorMessageEdit: "",
        successMessageEdit: "",
        logo_view: "",
        image_title: "",
      });
    }, 4000);
  };

  deleteImage = async (id) => {
    const saveResponce = await deleteUserImage(
      auth.getAccount(),
      auth.getToken(),
      id
    );
    console.log("ADD RES: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: "There is some error while deleting the image.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "image_type"
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage:
          "Cannot delete the image of this type. At least one image of this type should exists.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "assigned_to_sharelink"
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage:
          "Cannot delete the image. This image is assigned to the sharelink. Please change the image in sharelink and try again.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Image deleted successfully.",
        disabled: false,
      });

      this.getImageLibrary();
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the image.",
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  hide_messages = () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
      errorMessageEdit: "",
      successMessageEdit: "",
      disabled: false,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if(name === "images_type"){
      const inputElement = document.getElementById('picture');
      if(inputElement){
        const selectedFile = inputElement.files[0];
        if(selectedFile){
          inputElement.value = null;
          this.setState({
            errorMessageEdit: '',
            picture: "",
            pictureName: "",
          });
        }
      }
    }
    this.setState({
      [name]: value,
    });
    console.log("handleChange:", name + " " + value);
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      about_me: content,
    });
    //console.log("Content was updated:", content);
  };

  handleFile = async (e) => {
    const selectedFile = e.target.files[0];
    const image_type = this.state.images_type;
    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        errorMessageEdit:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 5MB)
    const maxSizeBytes = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        errorMessageEdit:
          "File size exceeds the limit (5MB). Please choose a smaller file.",
      });
      return;
    }

    // Image dimension validation
    const imageReader = new FileReader();

    imageReader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      let maxWidth = 0; 
      let maxHeight = 0;
      let minWidth = 0; 
      let minHeight = 0;
      image.onload = () => {
        if(image_type === 'logo'){
          maxWidth = 320; 
          maxHeight = 110;
          minWidth = 300; 
          minHeight = 90;
        } else if (image_type === 'image'){
          maxWidth = 1560; 
          maxHeight = 850;
          minWidth = 1540; 
          minHeight = 830;
        } else if (image_type === 'banner'){
          maxWidth = 1930; 
          maxHeight = 1010;
          minWidth = 1910; 
          minHeight = 990;
        }

        if (image.width > maxWidth || image.height > maxHeight) {
          this.setState({
            errorMessageEdit: `Image dimensions exceed the limit (${maxWidth}x${maxHeight}). Please choose a smaller image.`,
          });
        } else if (image.width < minWidth || image.height < minHeight) {
          this.setState({
            errorMessageEdit: `Image dimensions should be at least (${minWidth}x${minHeight}). Please choose a larger image.`,
          });
        } else {
          // Clear any previous errors
          this.setState({
            picture: selectedFile,
            errorMessageEdit: "",
            pictureName: selectedFile.name,
          });
        }
      };
    };

    imageReader.readAsDataURL(selectedFile);
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  open_add_image = () => {
    window.$("#modalAddImage").modal("show");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle
                    name="Sharelink Settings"
                    icon="icon ni ni-setting"
                  />
                </h3>

                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div className="card-head">
                    <h5 className="card-title">
                      Add your sharelink settings below.
                    </h5>
                  </div>
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <br />
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tabItem5"
                      >
                        <em className="icon ni ni-user"></em>
                        <span>About Me</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabItem6"
                      >
                        <em className="icon ni ni-lock-alt"></em>
                        <span>Social Media Links</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabItem7"
                        onClick={() => this.getImageLibrary()}
                      >
                        <em className="icon ni ni-camera"></em>
                        <span>Image Library</span>
                      </a>
                    </li>
                    {/*<li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabItem8"
                      >
                        <em className="icon ni ni-link"></em>
                        <span>Domains</span>
                      </a>
                  </li>*/}
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="tabItem5">
                      <form
                        method="post"
                        className="serviceForm"
                        id="serviceForm"
                      >
                        <div className="row g-4">
                          <div className="col-lg-12">
                            <h4>Abut Me Details</h4>
                          </div>
                          <div className="col-lg-12">
                            <h6>Short Description</h6>
                          </div>
                          <div className="col-lg-12">
                            <TextareaAutosize
                              maxrows={4}
                              name="about_me_short"
                              id="about_me_short"
                              aria-label=""
                              placeholder=""
                              defaultValue={this.state.about_me_short}
                              onChange={this.handleChange}
                              style={{
                                width: "100%",
                                height: "100px",
                                borderColor: "rgba(0, 0, 0, 0.125)",
                                borderRadius: "4px",
                                padding: "5px",
                              }}
                              maxLength={50}
                              variant="outlined"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h6>About Me</h6>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              <Editor
                                id="about_me"
                                name="about_me"
                                apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                value={this.state.about_me}
                                selector="textarea#full-featured"
                                init={{
                                  height: 500,
                                  menubar: true,
                                  plugins: [
                                    "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                  ],
                                  toolbar:
                                    "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                }}
                                onEditorChange={this.handleEditorChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                onClick={() => this.formSave()}
                                disabled={this.state.disabled}
                              >
                                <em className="icon ni ni-note-add"></em>
                                {"  "}Save About Me
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="tab-pane" id="tabItem6">
                      <form
                        method="post"
                        className="serviceForm"
                        id="serviceForm2"
                      >
                        <div className="row g-4">
                          <div className="col-lg-12">
                            <h4>Social Media Links</h4>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="instagram_link">
                                Instagram Link
                              </InputLabel>
                              <OutlinedInput
                                id="instagram_link"
                                name="instagram_link"
                                value={this.state.instagram_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-instagram"></em>
                                  </InputAdornment>
                                }
                                labelWidth={110}
                              />
                            </FormControl>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="facebook_link">
                                Facebook Link
                              </InputLabel>
                              <OutlinedInput
                                id="facebook_link"
                                name="facebook_link"
                                value={this.state.facebook_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-facebook-fill"></em>
                                  </InputAdornment>
                                }
                                labelWidth={110}
                              />
                            </FormControl>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="twitter_link">
                                Twitter Link
                              </InputLabel>
                              <OutlinedInput
                                id="twitter_link"
                                name="twitter_link"
                                value={this.state.twitter_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-twitter-round"></em>
                                  </InputAdornment>
                                }
                                labelWidth={90}
                              />
                            </FormControl>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="linkedin_link">
                                Linkedin Link
                              </InputLabel>
                              <OutlinedInput
                                id="linkedin_link"
                                name="linkedin_link"
                                value={this.state.linkedin_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-linkedin-round"></em>
                                  </InputAdornment>
                                }
                                labelWidth={100}
                              />
                            </FormControl>
                          </div>

                          <div className="col-lg-12">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="youtube_link">
                                Youtube Link
                              </InputLabel>
                              <OutlinedInput
                                id="youtube_link"
                                name="youtube_link"
                                value={this.state.youtube_link}
                                onChange={this.handleChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <em className="icon ni ni-youtube"></em>
                                  </InputAdornment>
                                }
                                labelWidth={110}
                              />
                            </FormControl>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                onClick={this.formSaveSocial}
                                disabled={this.state.disabled}
                              >
                                <em className="icon ni ni-note-add"></em>
                                {"  "}Save Social Media Links
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="tab-pane" id="tabItem7">
                      {/* PAGE TITLE START */}
                      {this.state.tableLoaderListing === true ? (
                        tableLoaderListing()
                      ) : (
                        <>
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                  Image Library
                                </h3>
                              </div>
                              <div
                                className="nk-block-head-content"
                                id="HeadContent"
                              >
                                <div className="toggle-wrap nk-block-tools-toggle">
                                  <a
                                    href="#"
                                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                    data-target="pageMenu"
                                  >
                                    <em className="icon ni ni-more-v"></em>
                                  </a>
                                  <div
                                    className="toggle-expand-content"
                                    data-content="pageMenu"
                                  >
                                    <ul className="nk-block-tools g-3">
                                      <li className="nk-block-tools-opt">
                                        <Link
                                          color="inherit"
                                          onClick={() => this.open_add_image()}
                                          className="btn btn-success"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            color: "#fff",
                                          }}
                                        >
                                          Add New Image
                                          <em
                                            className="icon ni ni-upload ml-20 "
                                            style={{ marginLeft: "15px" }}
                                          ></em>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.imageData ? (
                            <div className="col-xxl-12">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Image</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.imageData.map((images) => (
                                    <tr key={images.id}>
                                      <td>
                                        <div className="nk-help-img">
                                          <img
                                            src={`${APP_LIVE_URL}files_data/sharelinks/${images.picture}`}
                                            alt={images.image_title}
                                          />
                                        </div>
                                      </td>
                                      <td>{images.image_title}</td>
                                      <td>{images.images_type}</td>
                                      <td>
                                        <div
                                          className="nk-tb-col nk-tb-col-tools"
                                          style={{ padding: "0px" }}
                                        >
                                          <ul className="nk-tb-actions gx-1">
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#"
                                                  title="Other Actions"
                                                  className="dropdown-toggle btn btn-icon  btn-outline-light"
                                                  data-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h"></em>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <a
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Delete Image"
                                                        onClick={() =>
                                                          this.deleteImage(
                                                            images.id
                                                          )
                                                        }
                                                      >
                                                        <em className="icon ni ni-trash"></em>
                                                        <span>
                                                          Delete Image
                                                        </span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                    <div className="tab-pane" id="tabItem8">
                      <p>Coming Soon</p>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              {/* DETAIL MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalAddImage">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-plus-round"></em> Add New
                        Image
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <div className="col-xxl-12">
                          <form
                            method="post"
                            encType="multipart/form-data"
                            onSubmit={this.formAddImage}
                          >
                            <div className="row g-4">
                              <div className="form-group col-md-12">
                                <MutextField
                                  required
                                  id="image_title"
                                  name="image_title"
                                  type="text"
                                  label="Image Title"
                                  inputProps={{ maxLength: 100 }}
                                  defaultValue={this.state.image_title}
                                  onChange={this.handleChange}
                                  helperText="Enter the title here."
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <MutextField
                                  id="images_type"
                                  name="images_type"
                                  select
                                  label="Image Type"
                                  defaultValue={this.state.images_type}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="e.g Logo, Avatar or Banner"
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option key="logo1" value="logo">
                                    Logo
                                  </option>
                                  <option key="image1" value="image">
                                    Avatar
                                  </option>
                                  <option key="Banner1" value="banner">
                                    Banner
                                  </option>
                                </MutextField>
                              </div>

                              <div className="form-group col-md-6">
                                <MutextField
                                  id="images_status"
                                  name="images_status"
                                  select
                                  label="Status"
                                  defaultValue={this.state.images_status}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="e.g Active or Inactive"
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option key="status1" value="1">
                                    Active
                                  </option>
                                  <option key="status0" value="0">
                                    Inactive
                                  </option>
                                </MutextField>
                              </div>

                              <div className="form-group col-md-6">
                                <div
                                  className="upload-btn-wrapper"
                                  style={{
                                    position: "relative",
                                    overflow: "hidden",
                                    display: "inline-block",
                                  }}
                                >
                                  <button
                                    style={{
                                      border: "1px solid gray",
                                      cursor: "pointer",
                                      color: "#364a63",
                                      backgroundColor: "white",
                                      padding: "6px 20px",
                                      borderRadius: "4px",
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <em className="icon ni ni-upload"></em>{" "}
                                    Choose a file to upload{" "}
                                    <small>(.jpg or .png only)</small>
                                  </button>
                                  <input
                                    type="file"
                                    name="picture"
                                    id="picture"
                                    accept=".png, .jpg"
                                    onChange={this.handleFile}
                                    defaultValue={this.state.picture}
                                    style={{
                                      border: "1px solid gray",
                                      position: "absolute",
                                      left: "0",
                                      top: "0",
                                      opacity: "0",
                                      fontSize: "100px",
                                    }}
                                  />
                                </div>
                                <div>
                                  <span className="badge badge-outline-primary">
                                    {this.state.pictureName}
                                  </span>
                                </div>
                              </div>
                              <div className="col-xxl-6">
                                {this.state.logo_view !== "" ? (
                                  <img
                                    style={{ height: "100px" }}
                                    src={this.state.logo_view}
                                  ></img>
                                ) : null}
                              </div>

                              <div className="col-md-12">
                                                        <div className="example-alert">
                                                          <div className="alert alert-light">
                                                            <ul className="list">
                                                              <li>
                                                                Accepted
                                                                Formats: PNG,
                                                                JPEG, JPG
                                                              </li>
                                                              <li>
                                                                Recommended
                                                                Size: 5MB
                                                              </li>
                                                              <li>Recommended Dimensions:
                                                                  <b> Logo (W:310 x H:100) - Avatar (W:1550 x H:840) - Banner (W:1920 x H:1000)</b>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>

                              <div style={{ clear: "both" }}></div>
                              <hr />
                              <div className="form-group text-right col-md-12">
                                <button
                                  className="btn btn-lg btn-primary"
                                  type="submit"
                                  disabled={this.state.disabled}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SharelinkSettings);
