import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import $ from "jquery";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./styles.css";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";

import Services from "../Services/index";
import Analytics from "../Analytics/index";

import { addSettings, addMsaInfo } from "../../../actions";
import { COMPANY_NAME } from "../../../config/config";
import { listServices, getDashboardCounts } from "./../../../config/api_calls";
import { dateString, dateFirstString } from "./../../../config/config";

import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
} from "./../../../config/config";
import HelperClass from "./../../../config/helperClass";
const auth = new HelperClass();

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      totalRegistered: 0,
      totalActive: 0,
      monthlyBills: "0.00",
      paidBills: "0.00",
      columnServices: [
        { name: "Id" },
        { name: "Title" },
        {
          name: "Details",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewBtns()}</div>;
            },
          },
        },
        { name: "Nrc($)" },
        { name: "Mrc($)" },
        { name: "Rate Type" },
        { name: "Rate($)" },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <CreateIcon
                    style={{
                      cursor: "pointer",
                    }}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  />{" "}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setMarkUp(value, tableMeta)}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  >
                    Set Rates
                  </a>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  setMarkUp = () => {
    //window.$("#modalForm").modal("show");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  async componentDidMount() {
    console.log("dateFirstString: ", dateFirstString);
    await this.setServiceList();
    await this.setGraphs();
  }

  viewModal = async (value, tableMeta) => {
    console.log("[viewModal]value: ", value);
    // console.log("[viewModal]tableMeta: ", tableMeta.rowIndex);
  };

  setGraphs = async () => {
    let getCountsInfo = await getDashboardCounts(
      auth.getAccount(),
      auth.getToken(),
      dateFirstString,
      dateString
    );
    console.log("Totals: ", getCountsInfo.data.data);
    if (
      getCountsInfo.data.status === 403 ||
      getCountsInfo.data.errors === "authentication missing" ||
      getCountsInfo.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getCountsInfo.data.status === 404) {
      // window.location.replace("/error");
    } else if (
      getCountsInfo.data.status === 200 &&
      getCountsInfo.data.message === "success"
    ) {
      /*this.setState({
        totalRegistered: getCountsInfo.data.data["total_registered"],
        totalActive: getCountsInfo.data.data["total_active"],
        monthlyBills: getCountsInfo.data.data["monthly_bills"],
        paidBills: getCountsInfo.data.data["paid_bills"],
      });*/
      this.setState({
        totalRegistered: 0,
        totalActive: 0,
        monthlyBills: 0,
        paidBills: 0,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  setServiceList = async () => {
    const servicesResponce = await listServices(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  // header = () => {
  //   return (
  //     <div className="nk-block-head nk-block-head-sm">
  //       <div className="nk-block-between">
  //         <div className="nk-block-head-content" style={{ mrginLeft: "2.5%" }}>
  //           <h3 className="nk-block-title page-title">
  //             <strong>Welcome to</strong> {auth.getCompName()}{" "}
  //             <strong>Dashboard</strong>
  //           </h3>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  render() {
    $("#Services_Block.nk-content").css("padding", "0px");
    $("#Services_Block.nk-content .container-fluid").css("padding-left", "0px");
    $("#Services_Block.nk-content .container-fluid").css(
      "padding-right",
      "0px"
    );

    $("#Analytics_Block.nk-content").css("padding", "0px");
    $("#Analytics_Block.nk-content").css("padding-bottom", "20px");
    $("#Analytics_Block.nk-content .container-fluid").css(
      "padding-left",
      "0px"
    );
    $("#Analytics_Block.nk-content .container-fluid").css(
      "padding-right",
      "0px"
    );
    $("#HeadContent").hide();
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="nk-block-head nk-block-head-sm">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title">
                        <strong>Welcome to</strong> {auth.getCompName()}{" "}
                        <strong>Dashboard</strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* {this.header()} */}

              <div className="nk-block">
                {/* FIRST ROW START */}

                <div className="row g-gs" style={{ clear: "both" }}>
                  <div className="col-lg-12 col-xxl-12">
                    <div
                      className="card card-bordered"
                      style={{ clear: "both" }}
                    >
                      <div className="card-inner">
                        <div className="card-title-group pb-3 g-2">
                          <div className="card-title card-title">
                            <h4 className="title">Executive Summary</h4>
                            <p>Overall User / System and Revenue Analysis</p>
                          </div>
                        </div>
                        <div className="analytic-ov">
                          <div className="analytic-data-group analytic-ov-group g-3">
                            <div className="col-sm-3 col-lg-3 col-xxl-3">
                              <div className="nk-order-ovwg-data buy  text-center">
                                <div className="amount">
                                  {this.state.totalRegistered}{" "}
                                  <small className="currenct currency-usd"></small>
                                </div>
                                <div className="info">
                                  Total registered companies{" "}
                                  <strong>
                                    {" "}
                                    <span className="currenct currency-usd"></span>
                                  </strong>
                                </div>
                                <div className="title">
                                  <em className="icon ni ni-users"></em> Total
                                  Registered
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-xxl-3">
                              <div className="nk-order-ovwg-data sell  text-center">
                                <div className="amount">
                                  {this.state.totalActive}{" "}
                                  <small className="currenct currency-usd"></small>
                                </div>
                                <div className="info">
                                  Total active companies{" "}
                                  <strong>
                                    {" "}
                                    <span className="currenct currency-usd"></span>
                                  </strong>
                                </div>
                                <div className="title">
                                  <em className="icon ni ni-user-check"></em>{" "}
                                  Total Active
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-xxl-3">
                              <div className="nk-order-ovwg-data buy  text-center">
                                <div className="amount">
                                  {this.state.monthlyBills}
                                </div>
                                <div className="info">
                                  Total DID's{" "}
                                  <strong>
                                    {" "}
                                    <span className="currenct currency-usd"></span>
                                  </strong>
                                </div>
                                <div className="title">
                                  <em className="icon ni ni-mic"></em> Total
                                  DID's
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-xxl-3">
                              <div className="nk-order-ovwg-data sell  text-center">
                                <div className="amount">
                                  ${this.state.paidBills}{" "}
                                  <small className="currenct currency-usd">
                                    USD
                                  </small>
                                </div>
                                <div className="info">
                                  Total Paid (Inc. Credit)
                                  <strong>
                                    {" "}
                                    <span className="currenct currency-usd"></span>
                                  </strong>
                                </div>
                                <div className="title">
                                  <em className="icon ni ni-sign-usd-alt"></em>{" "}
                                  Paid Bills
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <Analytics from="dashboard" />
              <Services from="dashboard" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
