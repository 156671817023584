import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";

import $ from "jquery";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { singleSignOn } from "../../../config/api_calls";
import HelperClass from "../../../config/helperClass";
const auth = new HelperClass();

export default class Sso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
    };
  }

  async componentDidMount() {
    let reseller_url = this.props.match.params.reseller_url;
    let token_code = this.props.match.params.token_code;
    console.log("reseller_url: "+reseller_url+" token_code: "+token_code);
    if(reseller_url === "" && token_code === ""){
      this.setState({
        errorMessage:"URL is not valid. Please enter the valid URL."
      });
    } else {
      let apiHit = await singleSignOn(token_code,reseller_url);
      console.log("apiHit.data: ", apiHit.data);
      if (
        apiHit.data.status === 404 ||
        apiHit.data.message === "Invalid Credientials"
      ) {
        //window.location.replace("/error");
        this.setState({
          errorMessage: "Invalid credentials or expired token.",
          successMessage: "",
        });
      } else if (
        apiHit.data.status === 200 &&
        apiHit.data.message === "success"
      ) {
        //Remove Previous Session
        Cookies.remove("comp_nme__");
        Cookies.remove("eml__");
        Cookies.remove("acn__");
        Cookies.remove("tkn__");
        Cookies.remove("residentifier__");
        Cookies.remove("restitle__");
        Cookies.remove("ressig__");
        Cookies.remove("restkn__");
        Cookies.remove("resacct__");
        Cookies.remove("name__");
        Cookies.remove("title__");

        Cookies.set(
          "tkn__",
          CryptoJS.AES.encrypt(
            apiHit.data.token,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "acn__",
          CryptoJS.AES.encrypt(
            apiHit.data.accountno,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "eml__",
          CryptoJS.AES.encrypt(
            apiHit.data.email,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "comp_nme__",
          CryptoJS.AES.encrypt(
            apiHit.data.comp_name,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "residentifier__",
          CryptoJS.AES.encrypt(
            apiHit.data.reseller_url,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "restitle__",
          CryptoJS.AES.encrypt(
            apiHit.data.reseller_title,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "reslogo__",
          CryptoJS.AES.encrypt(
            apiHit.data.reseller_logo,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "ressig__",
          CryptoJS.AES.encrypt(
            apiHit.data.reseller_signature,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "restkn__",
          CryptoJS.AES.encrypt(
            apiHit.data.reseller_hash,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "resacct__",
          CryptoJS.AES.encrypt(
            apiHit.data.res_accountno,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "name__",
          CryptoJS.AES.encrypt(
            apiHit.data.name,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );

        Cookies.set(
          "title__",
          CryptoJS.AES.encrypt(
            apiHit.data.title,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        this.setState({
          errorMessage: "",
          successMessage: "Account authenticated successfully.",
        });
        setTimeout(function () {
          window.location.replace("/dashboard");
        }, 3000);
      } else {
        this.setState({
          errorMessage:
            "Your account cannot be authenticated this time. Please check the URL or try again later.",
          successMessage: "",
        });
      }
    }
    
  }

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {this.state.errorMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.successMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-success">
                    <div className="alert-text">
                      <h4>Success</h4>
                      <p>{this.state.successMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="text-center" style={{ marginTop: "20%" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
