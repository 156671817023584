import React from "react";
import ContentLoader from "react-content-loader";
const newWidth = window.innerWidth * 1;
export const HeadBodyGrid = (props) => (
  <ContentLoader height="500" width={newWidth} viewBox={`0 0 ${newWidth} 350`} {...props}>
    
    <rect x="15" y="0" rx="2" ry="2" width="100%" height="350" />
    
  </ContentLoader>
);
