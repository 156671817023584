import { React, Component } from "react";

import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { checkAgent } from "./../../../config/api_calls";
import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";
import { ValidateEmail, axiosConfig, normalize } from "../../../config/utility";

import { resendCodeFun } from "../../../config/api_calls";

import TextField from "./TextField";
import { tableLoader } from "../../../components/ContentLoaders/table_loader_register";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import HelperClass from "./../../../config/helperClass";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/build/index.css';
import ReCAPTCHA from "react-google-recaptcha";



const auth = new HelperClass();

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      comp_name: "",
      title: "",
      email: "",
      password: "",
      showPassword: false,
      agent_address: "",
      agent_address2: "",
      agent_city: "",
      agent_state: "",
      agent_zip: "",
      errorEmail: "",
      errorPass: "",
      errorcPass: "",
      errorName: "",
      errorAddress: "",
      errorCity: "",
      errorState: "",
      errorZip: "",
      errorMessage: "",
      errorCompName: "",
      errorTitle: "",
      disabled: false,
      successMessage: "",
      confirm_password: "",
      showConfirmPassword: false,
      errorConfirm: "",
      disableResendBtn: false,
      phone: "",
      errorPhone: "",
      fieldsHide: false,
      show_form: false,
      resellerData: [],
      tableLoader: true,
      res_accountno: "",
      res_title: "",
      super_agent_accountno: "",
      agent_title: "",
      action_type: "",
      pageError: "",
      res_url: "",
      url_type: "Simple",
    };
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.toggleConfirmPasswordVisibility =
      this.toggleConfirmPasswordVisibility.bind(this);
      this.site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
      this.captcha_token = null;  
  }

  async componentDidMount() {
    let reseller_url = this.props.match.params.reseller_url;
    let reseller_hash = this.props.match.params.reseller_hash;
    let action_type = "agent";
    if (this.props.match.params.action_type === "sub") {
      action_type = "sub";
    }
    console.log("this.props: ", this.props);
    console.log(
      "URL Details: ",
      reseller_url + " " + reseller_hash + " " + action_type
    );
    let urlElements = window.location.href.split("/");
    console.log("CUrrent URL: ", urlElements[0] + "//" + urlElements[2]);
    let host_url = urlElements[2];
    console.log("host_url: ", host_url);
    /*this.setState({ tableLoader: false });*/
    if (reseller_hash !== "") {
      //Errors
      //404 and no_reseller
      //404 and no_agent
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "agents/check_reseller",
            JSON.stringify({
              reseller_url: reseller_url,
              reseller_hash: reseller_hash,
              action_type: action_type,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              host_url: host_url,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("response.data : ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessage: "There is some error.",
                successMessage: "",
                show_form: false,
                pageError: "",
              });
            }

            if (
              response.data.status === 404 &&
              response.data.message === "no_reseller"
            ) {
              this.setState({
                errorMessage: "",
                pageError:
                  "Reseller does not exists. Please contact support or try again with correct URL.",
                successMessage: "",
                show_form: false,
              });
            } else if (
              response.data.status === 404 &&
              response.data.message === "no_agent"
            ) {
              this.setState({
                pageError:
                  "Agent does not exists. Please contact support or try again with correct sub agent registration URL.",
                errorMessage: "",
                successMessage: "",
                show_form: false,
              });
            } else if (response.data.status === 403) {
              this.setState({
                errorMessage: "There is some error.",
                successMessage: "",
                show_form: false,
                pageError: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              Cookies.set(
                "residentifier__",
                CryptoJS.AES.encrypt(
                  reseller_url,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "restkn__",
                CryptoJS.AES.encrypt(
                  reseller_hash,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "resacct__",
                CryptoJS.AES.encrypt(
                  response.data.res_accountno,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "resregtype__",
                CryptoJS.AES.encrypt(
                  response.data.action_type,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "restitle__",
                CryptoJS.AES.encrypt(
                  response.data.reseller_title,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              if (action_type === "sub") {
                Cookies.set(
                  "agttitle__",
                  CryptoJS.AES.encrypt(
                    response.data.agent_title,
                    process.env.REACT_APP_SIGNATURE
                  ).toString()
                );
                Cookies.set(
                  "agtacctno__",
                  CryptoJS.AES.encrypt(
                    response.data.agent_accountno,
                    process.env.REACT_APP_SIGNATURE
                  ).toString()
                );
              }

              Cookies.set(
                "reslogo__",
                CryptoJS.AES.encrypt(
                  response.data.reseller_logo,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              Cookies.set(
                "ressig__",
                CryptoJS.AES.encrypt(
                  response.data.reseller_signature,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              Cookies.set(
                "urltype__",
                CryptoJS.AES.encrypt(
                  response.data.url_type,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              this.setState({
                errorMessage: "",
                res_accountno: response.data.res_accountno,
                res_title: response.data.reseller_title,
                resellerData: response.data,
                tableLoader: false,
                show_form: true,
                agent_title: response.data.agent_title,
                super_agent_accountno: response.data.agent_accountno,
                action_type: action_type,
                res_url: reseller_url,
                pageError: "",
              });
            }
          });
      } catch (error) {
        this.setState({
          errorMessage: "There is some error.",
          successMessage: "",
          show_form: false,
          pageError: "",
        });
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
    }
  }

  togglePasswordVisibility() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  toggleConfirmPasswordVisibility() {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCaptchaChange = (value) => {
    this.captcha_token = value;
  };

  fieldValidation = () => {
    let {
      name,
      email,
      password,
      confirm_password,
      phone,
      comp_name,
      title,
      agent_address,
      agent_address2,
      agent_city,
      agent_state,
      agent_zip,
    } = this.state;
    console.log(
      "agent_address: " +
        this.state.agent_address +
        " " +
        agent_city +
        " " +
        agent_state +
        " " +
        agent_zip
    );
    if (comp_name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Company Name is required.",
        errorcPass: "",
      });
      return false;
    } else if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
        errorcPass: "",
      });
      return false;
    } /*else if (title === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorTitle: "error",
        errorMessage: "Title is required.",
        errorcPass: "",
      });
      return false;
    }*/ else if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorTitle: "",
        errorMessage: "Email is required.",
        errorcPass: "",
      });
      return false;
    } else if (phone === "") {
      this.setState({
        errorPhone: "error",
        errorEmail: "",
        errorMessage: "Phone number is required.",
        errorTitle: "",
        errorcPass: "",
      });
      return false;
    } /*else if (normalize(phone) === null) {
      this.setState({
        errorPhone: "error",
        errorEmail: "",
        errorMessage: "Invalid phone format.",
        errorcPass: "",
      });
      return false;
    }*/ else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
        errorcPass: "",
      });
      return false;
    } else if (agent_address === "") {
      this.setState({
        errorAddress: "error",
        errorPhone: "",
        errorEmail: "",
        errorMessage: "Address is required.",
        errorTitle: "",
        errorcPass: "",
      });
      return false;
    } else if (agent_city === "") {
      this.setState({
        errorCity: "error",
        errorPhone: "",
        errorEmail: "",
        errorMessage: "City is required.",
        errorTitle: "",
        errorcPass: "",
      });
      return false;
    } else if (agent_state === "") {
      this.setState({
        errorState: "error",
        errorPhone: "",
        errorEmail: "",
        errorMessage: "State is required.",
        errorTitle: "",
        errorcPass: "",
      });
      return false;
    } else if (agent_zip === "") {
      this.setState({
        errorZip: "error",
        errorPhone: "",
        errorEmail: "",
        errorMessage: "Zip / postal code is required.",
        errorTitle: "",
        errorcPass: "",
      });
      return false;
    } else if (password === "") {
      this.setState({
        errorPass: "error",
        errorEmail: "",
        errorcPass: "",
        errorPhone: "",
        errorMessage: "Password is required.",
      });
      return false;
    } else if (password.length < 8) {
      this.setState({
        errorPass: "error",
        errorEmail: "",
        errorcPass: "",
        errorPhone: "",
        errorMessage: "Use 8 characters or more for your password",
      });
      return false;
    } else if (confirm_password === "") {
      this.setState({
        errorPass: "",
        errorcPass: "error",
        errorEmail: "",
        errorPhone: "",
        errorMessage: "Confirm password is required.",
      });
      return false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorPass: "",
        errorcPass: "error",
        errorEmail: "",

        errorPhone: "",
        errorMessage: "Use 8 characters or more for your confirm password",
      });
      return false;
    } else if (password !== confirm_password) {
      this.setState({
        errorPass: "",
        errorcPass: "error",
        errorEmail: "",

        errorPhone: "",
        errorMessage: "Password and confirm password does not match.",
      });
      return false;
    } else if (this.captcha_token === "" || this.captcha_token === null) {
      this.setState({
        errorMessage: "Invalid captcha.",
      });
      return false;
    } else {
      this.setState({
        errorcPass: "",
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        disabled: true,
      });
    }
    return true;
  };

  formSubmit = (e) => {
    e.preventDefault();
    let {
      name,
      email,
      password,
      confirm_password,
      phone,
      comp_name,
      title,
      agent_address,
      agent_address2,
      agent_city,
      agent_state,
      agent_zip,
    } = this.state;

    if (this.fieldValidation() === true) {
      let agent_title = "";
      let super_agent_accountno = "";
      if (this.state.action_type === "sub") {
        agent_title = this.state.agent_title;
        super_agent_accountno = this.state.super_agent_accountno;
      }
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "register",
            JSON.stringify({
              name: name,
              email: email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              phone: phone,
              comp_name: comp_name,
              title: title,
              res_accountno: this.state.res_accountno,
              agent_title: agent_title,
              super_agent_accountno: super_agent_accountno,
              action_type: this.state.action_type,
              agent_address: agent_address,
              agent_address2: agent_address2,
              agent_city: agent_city,
              agent_state: agent_state,
              agent_zip: agent_zip,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("response.data : ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessage: "Invalid Email OR Password",
                disabled: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "Account Already Exists"
            ) {
              this.setState({
                errorMessage: response.data.message,
                disabled: false,
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "Success"
            ) {
              $("#resendBtn").show();
              $("#signupBtn").hide();

              this.setState({
                successMessage: `Email Sent to your account. To complete
                    verification process please go to inbox.`,
                errorMessage: "",
                fieldsHide: true,
              });
            }
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    }
  };

  resendEmail = async () => {
    let { email } = this.state;

    if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Email is required.",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      return false;
    } else {
      this.setState({
        errorEmail: "",
        errorMessage: "",
        disableResendBtn: true,
      });

      let resendRes = await resendCodeFun(email);
      console.log("resendRes: ", resendRes.data);
      if (resendRes.data.status === 404 || resendRes.data.errors !== "") {
        this.setState({
          errorMessage: resendRes.data.errors,
          disableResendBtn: false,
          successMessage: "",
        });
      } else if (
        resendRes.data.status === 200 &&
        resendRes.data.message === "Success" &&
        resendRes.data.errors === ""
      ) {
        this.setState({
          successMessage: `Email Sent.Please check inbox/spam for account activation.`,
          errorMessage: "",
          disableResendBtn: false,
        });
      } else {
        window.location.replace("/error");
      }
    }
  };
  render() {
    console.log("fieldsHide: ", this.state.fieldsHide);
    return (
      <div className="nk-body npc-crypto ui-clean pg-auth">
        <div className="nk-app-root">
          <div className="nk-split nk-split-page nk-split-md registrations">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn btn-white btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5 d-flex justify-content-center">
                  {auth.getResellerLogo() !== "" ? (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                        alt="logo-dark"
                      />
                    </a>
                  ) : (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo-dark"
                      />
                    </a>
                  )}
                </div>
                {this.state.pageError !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.pageError}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.tableLoader === true ? (
                      FormLoader()
                    ) : (
                      <>
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            {this.state.action_type === "sub" ? (
                              <h5 className="nk-block-title">
                                Register Sub Agent of {this.state.agent_title}{" "}
                                for {this.state.res_title}
                              </h5>
                            ) : null}
                            {this.state.action_type !== "sub" ? (
                              <h5 className="nk-block-title">
                                Register Agent of {this.state.res_title}
                              </h5>
                            ) : null}
                            <div className="nk-block-des">
                              {/* Error Start */}
                              {this.state.errorMessage !== "" ? (
                                <div className="example-alert">
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {/* Error End */}

                              {/* Resend Email Button Start */}
                              {this.state.successMessage !== "" ? (
                                <div className="example-alert">
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.successMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {/* Resend Email Button End */}
                            </div>
                          </div>
                        </div>
                        <form
                          method="post"
                          className="registerForm"
                          onSubmit={this.formSubmit}
                        >
                          {this.state.fieldsHide === false ? (
                            <div className="row g-gs">
                              <div className="col-md-6">
                                <TextField
                                  label="Company Name *"
                                  error={this.state.errorCompName}
                                  id="comp_name"
                                  name="comp_name"
                                  value={this.state.comp_name}
                                  placeholder="Company name"
                                  change={this.handleChange}
                                />
                              </div>
                              <div className="col-md-6">
                                <TextField
                                  label="Full Name *"
                                  error={this.state.errorName}
                                  id="name"
                                  name="name"
                                  value={this.state.name}
                                  placeholder="Full Name"
                                  change={this.handleChange}
                                />
                              </div>
                              <div className="col-md-6">
                                <TextField
                                  label="Title"
                                  error={this.state.errorTitle}
                                  id="title"
                                  name="title"
                                  value={this.state.title}
                                  placeholder="Title"
                                  change={this.handleChange}
                                />
                              </div>
                              <div className="col-md-6">
                                <TextField
                                  label="Email *"
                                  type="text"
                                  error={this.state.errorEmail}
                                  id="email"
                                  name="email"
                                  value={this.state.email}
                                  placeholder="Enter your email address"
                                  change={this.handleChange}
                                />
                              </div>
                              <div className="col-md-6">
                              <label className="form-label" htmlFor="name">
                                Phone Number *
                              </label>
                                <PhoneInput
                                  required
                                  defaultCountry={"us"}
                                  error={this.state.errorPhone}
                                  inputProps={{
                                    name: "phone",
                                    id: "phone",
                                    maxLength: 20,
                                  }}
                                  value={this.state.phone}
                                  onChange={(value) =>
                                    this.handleChange({
                                      target: { name: "phone", value },
                                    })
                                  }
                                  helperText="Enter the phone number."
                                  label="Phone Number"
                                />
                                {/*<TextField
                                  label="Phone"
                                  type="text"
                                  error={this.state.errorPhone}
                                  id="phone"
                                  name="phone"
                                  value={this.state.phone}
                                  placeholder="Enter your phone"
                                  change={this.handleChange}
                                  inputProps={{ maxLength: 20 }}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/gi,
                                      ""
                                    );
                                  }}
                                />*/}
                              </div>
                              <div className="col-md-6">
                                <TextField
                                  id="agent_address"
                                  name="agent_address"
                                  label="Address *"
                                  inputProps={{ maxLength: 100 }}
                                  value={this.state.agent_address}
                                  change={this.handleChange}
                                />
                              </div>

                              <div className="col-md-6">
                                <TextField
                                  id="agent_address2"
                                  name="agent_address2"
                                  label="Address 2"
                                  value={this.state.agent_address2}
                                  change={this.handleChange}
                                  inputProps={{ maxLength: 100 }}
                                />
                              </div>

                              <div className="col-md-2">
                                <TextField
                                  id="agent_city"
                                  name="agent_city"
                                  label="City *"
                                  value={this.state.agent_city}
                                  inputProps={{ maxLength: 32 }}
                                  change={this.handleChange}
                                />
                              </div>
                              <div className="col-md-2">
                                <TextField
                                  id="agent_state"
                                  name="agent_state"
                                  label="State *"
                                  value={this.state.agent_state}
                                  inputProps={{ maxLength: 3 }}
                                  change={this.handleChange}
                                />
                              </div>
                              <div className="col-md-2">
                                <TextField
                                  id="agent_zip"
                                  name="agent_zip"
                                  label="Zip / Postal Code *"
                                  value={this.state.agent_zip}
                                  inputProps={{ maxLength: 9 }}
                                  change={this.handleChange}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="password"
                                >
                                  Password *
                                </label>
                                <div className="form-control-wrap">
                                  <a
                                    tabIndex="-1"
                                    className="form-icon form-icon-right passcode-switch"
                                    onClick={this.togglePasswordVisibility}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {this.state.showPassword ? (
                                      <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                                    ) : (
                                      <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                    )}
                                  </a>
                                  <input
                                    type={
                                      this.state.showPassword
                                        ? "text"
                                        : "password"
                                    }
                                    className={
                                      "form-control form-control-lg " +
                                      this.state.errorPass
                                    }
                                    id="password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    placeholder="Enter your password"
                                  />
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="confirm_password"
                                >
                                  Confirm Password *
                                </label>
                                <div className="form-control-wrap">
                                  <a
                                    tabIndex="-1"
                                    className="form-icon form-icon-right passcode-switch"
                                    onClick={
                                      this.toggleConfirmPasswordVisibility
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {this.state.showConfirmPassword ? (
                                      <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                                    ) : (
                                      <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                    )}
                                  </a>
                                  <input
                                    type={
                                      this.state.showConfirmPassword
                                        ? "text"
                                        : "password"
                                    }
                                    className={
                                      "form-control form-control-lg " +
                                      this.state.errorcPass
                                    }
                                    id="confirm_password"
                                    name="confirm_password"
                                    value={this.state.confirm_password}
                                    onChange={this.handleChange}
                                    placeholder="Confirm your password"
                                  />
                                </div>
                              </div>
                              <div className="form-group col-md-12">
                                <ReCAPTCHA
                                  sitekey={this.site_key}
                                  onChange={this.handleCaptchaChange}
                                  style={{ padding: "0px 0px 15px" }}
                                />
                              </div>
                            </div>
                          ) : null}
                          <br />

                          <div className="form-group">
                            <button
                              className="btn btn btn-dim btn-primary"
                              onClick={() => this.resendEmail()}
                              disabled={this.state.disableResendBtn}
                              id="resendBtn"
                              style={{ display: "none" }}
                            >
                              Resend Email
                            </button>{" "}
                            <button
                              id="signupBtn"
                              className="btn btn-lg btn-block"
                              type="submit"
                              disabled={this.state.disabled}
                              style={{
                                backgroundColor: THEME_COLOR,
                                color: "white",
                              }}
                            >
                              Register
                            </button>
                          </div>
                        </form>
                      </>
                    )}

                    <div className="form-note-s2 pt-4">
                      {" "}
                      Already have an account ?{" "}
                      {auth.getUrlType() === "Simple" ? (
                        <a href={`/${this.state.res_url}`}>
                          <strong>Sign in instead</strong>
                        </a>
                      ) : (
                        <a href="/">
                          <strong>Sign in instead</strong>
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="nk-block nk-auth-footer d-flex justify-content-center">
                <div className="mt-3">
                  <p>
                    &copy; {new Date().getFullYear()}{" "}
                    <a
                      href="https://onboardsoft.com/"
                      style={{ color: "#526484" }}
                    >
                      OnBoardSoft LLC
                    </a>
                    . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
            {/*<div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div
                  className="slider-init"
                  data-slick='{"dots":true, "arrows":false}'
                >
                  <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_A} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>
                          {this.state.res_title !== "" ? (
                            <>
                              {this.state.res_title} Agents / Channel Partners
                            </>
                          ) : (
                            <>{COMPANY_NAME}</>
                          )}
                        </h4>
                        <p>
                          Enter details here to signup and start using our
                          exciting product.
                        </p>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="slider-dots"></div>
                <div className="slider-arrows"></div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}
