import { React } from "react";
import { Component } from "react";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  ValidateEmail,
  axiosConfig,
  normalizePhone,
  ValidatePasswordPattern,
} from "../../../config/utility";
import {
  getRfqInitialDetails,
  addBusinessInformation,
  addFinancialInformation,
  addTradeReferences,
  saveSignature,
  saveChooseSignature,
  configureCreditApplication,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import Questions from "../../../components/Client/RequestForQuotation/questions";
import ExtraDetails from "../../../components/Client/RequestForQuotation/extra";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { McqLoader } from "../../../components/ContentLoaders/newLoaders/Mcq";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import html2canvas from "html2canvas";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import { Editor } from "@tinymce/tinymce-react";

const auth = new HelperClass();

class AddRfq extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      tableDataBundle: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      errorMessageFinance: "",
      successMessageFinance: "",
      errorMessageTrade: "",
      errorMessageSignatory: "",
      successMessageSignatory: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      mcqLoader: true,
      tableLoaderBundle: true,
      errorMessageEmail: "",
      VerifyBusinessButton: false,
      VerifyFinancialButton: false,
      VerifyTradeButton: false,
      verifyDetailsButton: false,
      resendPinButton: false,
      successMessageEmail: "",
      errorMessagePassword: "",
      errorMessageConsent: "",
      errorMessageFinal: "",
      step: 1,
      email: "",
      disabled: false,
      //Business Info
      customer_name: "",
      customer_address1: "",
      customer_address2: "",
      customer_city: "",
      customer_state: "",
      customer_zip: "",
      customer_phone: "",
      duns_number: "",
      //Financial Info
      bank_details: false,
      bank_name: "",
      bank_contact: "",
      bank_address: "",
      bank_contact_phone: "",
      bank_contact_email: "",
      bank_routing_numbers: "",
      bank_account_number: "",
      //Trade Reference
      current_carrier_1: "",
      account_number_1: "",
      current_invoice_value_1: "",
      payment_terms_1: "",
      contact_name_1: "",
      contact_phone_1: "",
      contact_email_1: "",
      current_carrier_2: "",
      account_number_2: "",
      current_invoice_value_2: "",
      payment_terms_2: "",
      contact_name_2: "",
      contact_phone_2: "",
      contact_email_2: "",
      //Signatory Details
      sig_customer_name: "",
      sig_printed_name: "",
      sig_title: "",
      signatureText: "",
      signatureClass: "'Amita', cursive",
      signature_type: "draw",
      rfq_accountno: "",
      signature_image: "",

      rfq_comments: "",
      rfq_comments_error: "",
      rfq_answers: [{}],
      //rfq_questions_rows: [{}],
      step: 1,
      selectedFiles: [],
      file_upload_rows: [{}],
      services_rows: [{}],
      service_grand_total: 0,
      service_grand_sub_total: 0,
    };
    this.rfq_questions_rows = [];
  }

  async componentDidMount() {
    if (
      this.props.props.match.params.rfq_id &&
      this.props.props.match.params.rfq_id !== ""
    ) {
      let credit_id = this.props.props.match.params.rfq_id;
      this.setState({
        rfq_accountno: credit_id,
      });
      const saveResponce = await getRfqInitialDetails(
        auth.getClientAccount(),
        auth.getClientToken()
      );
      console.log("getRfqInitialDetails: ", saveResponce.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "Not Verified"
      ) {
        this.setState({
          mcqLoader: false,
        });
      } else if (saveResponce.data.status === 200) {
        let sharelink_services = [];

        if (
          saveResponce.data.servicesListing &&
          saveResponce.data.servicesListing.length > 0
        ) {
          let all_services = saveResponce.data.servicesListing;
          for (let i = 0; i < all_services.length; i++) {
            let innerArray = {
              service_checked: false,
              service_id: all_services[i]["id"],
              service_title: all_services[i]["service_title"],
              service_price: all_services[i]["rate"],
              service_quantity: 0,
              service_unit: "",
              service_sub_total: all_services[i]["rate"] * 1,
            };
            sharelink_services.push(innerArray);
          }
        }
        let rfq_questions_rows = [];
        if (
          saveResponce.data.rfqQuestionsListing &&
          saveResponce.data.rfqQuestionsListing.length > 0
        ) {
          let all_questions = saveResponce.data.rfqQuestionsListing;
          for (let i = 0; i < all_questions.length; i++) {
            let innerArray1 = {
              question_answered:
                all_questions[i]["question_options"] &&
                all_questions[i]["question_options"].length > 0 &&
                all_questions[i]["question_type"] !== "checkbox"
                  ? true
                  : false,
              question_id: all_questions[i]["id"],
              question_title: all_questions[i]["question_title"],
              question_type: all_questions[i]["question_type"],

              all_answers: all_questions[i]["question_options"],
            };
            let dynamicPropertyName =
              "selected_answer_" + all_questions[i]["id"];
            innerArray1[dynamicPropertyName] =
              all_questions[i]["question_options"] &&
              all_questions[i]["question_options"].length > 0 &&
              all_questions[i]["question_type"] !== "checkbox"
                ? all_questions[i]["question_options"][0]["id"]
                : all_questions[i]["question_options"] &&
                  all_questions[i]["question_options"].length > 0 &&
                  all_questions[i]["question_type"] === "checkbox"
                ? []
                : "";
            innerArray1["field_name"] = dynamicPropertyName;
            rfq_questions_rows.push(innerArray1);
          }
        }
        this.rfq_questions_rows = rfq_questions_rows;
        this.setState({
          errorMessage: "",
          services_rows: sharelink_services,
          //rfq_questions_rows: rfq_questions_rows,
          tableLoader: false,
          mcqLoader: false,
        });
      } else {
        this.setState({
          mcqLoader: false,
        });
      }
    }
  }

  fieldValidationUsername = () => {
    let { rfq_comments } = this.state;

    if (rfq_comments === "") {
      this.setState({
        errorMessage: "Customer requirements are required.",
        VerifyBusinessButton: false,
      });
      document
        .getElementById("rfq_Block")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });
    }
    return true;
  };

  submitRfq = async () => {
    //e.preventDefault();
    let {
      rfq_comments,
      add_picture,
      add_picture_name,
      //rfq_questions_rows,
      services_rows,
      rfq_accountno,
    } = this.state;

    this.setState({
      verifyPasswordButton: true,
    });
    console.log(
      "process.env.REACT_APP_API_TOKEN: ",
      process.env.REACT_APP_API_TOKEN
    );
    console.log(
      "process.env.REACT_APP_API_ACCOUNTNO: ",
      process.env.REACT_APP_API_ACCOUNTNO
    );
    if (this.fieldValidationUsername() === true) {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getClientAccount());
      formData.append("file", add_picture);
      formData.append("fileName", add_picture_name);
      formData.append("rfq_comments", rfq_comments);
      formData.append("rfq_accountno", rfq_accountno);
      formData.append("rfq_questions_rows", JSON.stringify(this.rfq_questions_rows));
      formData.append("services_rows", JSON.stringify(services_rows));

      console.log("formData: ", formData);

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "rfqs/submit_rfq",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitRfq response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                errorMessage: "Client does not found.",
                disabled: false,
                verifyPasswordButton: false,
                successMessage: "",
              });
              document
                .getElementById("rfq_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            } else if (
              response.data.status === 404 &&
              response.data.message === "already_exists"
            ) {
              this.setState({
                errorMessage:
                  "RFQ with this ID already exists. Click on 'Add New' to create new RFQ.",
                disabled: false,
                verifyPasswordButton: false,
                successMessage: "",
              });
              document
                .getElementById("rfq_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            } else if (response.data.status === 403) {
              this.setState({
                errorMessage: response.data.message,
                disabled: false,
                verifyPasswordButton: false,
                successMessage: "",
              });
              document
                .getElementById("rfq_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Your request for quotation is submitted successfully.",
                errorMessage: "",
                final_login_url: response.data.login_url,
                showLoginButton: true,
              });
              document
                .getElementById("rfq_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                window.location.replace(
                  "/" + auth.getResellerRegToken() + "/client/Rfqs"
                );
              }, 3000);

              //this.nextStep();
            } else {
              this.setState({
                errorMessage:
                  "There is some error while submitting the request for quotation.",
                disabled: false,
                successMessage: "",
                verifyPasswordButton: false,
              });
              document
                .getElementById("rfq_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            }
          });
      } catch (error) {
        this.setState({
          errorMessage:
            "There is some error while submitting the request for quotation.",
          disabled: false,
          successMessage: "",
          verifyPasswordButton: false,
        });
        document
          .getElementById("rfq_Block")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          this.setState({
            errorMessage: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    }
  };

  handleChangeRfq = (idx) => (e) => {
    //const { rfq_questions_rows } = this.state;
    let rfq_questions_rows = this.rfq_questions_rows;
    const { name, value } = e.target;

    let selected_key = name;
    if (rfq_questions_rows && rfq_questions_rows.length > 0) {
      for (let i = 0; i < rfq_questions_rows.length; i++) {
        var mm = Object.entries(rfq_questions_rows[i]);
        console.log("selected_key: " + selected_key);
        if (selected_key in rfq_questions_rows[i]) {
          if (value !== "") {
            rfq_questions_rows[i][selected_key] = value;
            rfq_questions_rows[i].question_answered = true;
          }
        }
      }
    }
    this.rfq_questions_rows = rfq_questions_rows;
    /*this.setState({
      rfq_questions_rows: rfq_questions_rows,
    });*/
    //
  };

  handleChangeRfqCheckboxes = (idx) => (e) => {
    //const { rfq_questions_rows } = this.state;

    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    console.log(
      "target.checked target.value: " + target.checked + " " + target.value
    );
    let rfq_questions_rows = this.rfq_questions_rows;
    let selected_key = name;
    if (rfq_questions_rows && rfq_questions_rows.length > 0) {
      for (let i = 0; i < rfq_questions_rows.length; i++) {
        console.log("selected_key: " + selected_key + " " + value);
        if (selected_key in rfq_questions_rows[i]) {
          console.log(
            "rfq_questions_rows[i]: " +
              JSON.stringify(rfq_questions_rows[i][selected_key])
          );
          if (target.checked) {
            
            rfq_questions_rows[i].selected_key = rfq_questions_rows[i][
              selected_key
            ].push(Number(target.value));
            rfq_questions_rows[i].question_answered = true;
          } else {
            var index = rfq_questions_rows[i][selected_key].indexOf(
              Number(target.value)
            );
            if (index !== -1) {
              rfq_questions_rows[i][selected_key].splice(index, 1);
            }
            if (rfq_questions_rows[i][selected_key].length === 0) {
              rfq_questions_rows[i].question_answered = false;
            }
            //rfq_questions_rows[i][selected_key] = value;
            //rfq_questions_rows[i].question_answered = false;
          }
        }
      }
    }
    this.rfq_questions_rows = rfq_questions_rows;
    /* this.setState({
      rfq_questions_rows: rfq_questions_rows,
    });*/
    console.log("rfq_questions_rows: ", this.rfq_questions_rows);
    //
  };



  handleEditorChange = (content, editor) => {
    this.setState({
      rfq_comments: content,
    });
    //console.log("Content was updated:", content);
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".pdf",
      ".doc",
      ".docx",
      ".csv",
      ".xlxs",
      ".xls",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        docErrorMessage:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 5MB)
    const maxSizeBytes = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        docErrorMessage:
          "File size exceeds the limit (5MB). Please choose a smaller file.",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      add_picture: selectedFile,
      docErrorMessage: "",
      add_picture_name: selectedFile.name,
    });
    //return true;
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  addNewService = async () => {
    const { select_service, services_rows } = this.state;
    console.log("select_service: ", select_service);
    if (select_service !== "") {
      if (services_rows && services_rows.length > 0) {
        for (let i = 0; i < services_rows.length; i++) {
          if (services_rows[i].service_id == select_service) {
            console.log(
              "services_rows[i].service_id: ",
              services_rows[i].service_id
            );
            services_rows[i].service_checked = true;
          }
        }
      }
      console.log("addNewService services_rows: ", services_rows);
      this.setState({
        services_rows: services_rows,
        select_service: "",
      });
    }
  };

  removeService = async (remove_service) => {
    const { services_rows } = this.state;

    if (services_rows && services_rows.length > 0) {
      for (let i = 0; i < services_rows.length; i++) {
        if (services_rows[i].service_id == remove_service) {
          services_rows[i].service_checked = false;
        }
      }
    }
    console.log("removeService services_rows: ", services_rows);
    this.setState({
      services_rows: services_rows,
      remove_service: "",
    });
  };

  handleChangeServices = (idx) => (e) => {
    const { name, value } = e.target;
    const services_rows = [...this.state.services_rows];
    console.log("services_rows[idx][value]: ", value);
    console.log("services_rows[idx][name]: ", name);
    let sub_total = 0;
    if (services_rows[idx]) {
      if (name === "service_quantity") {
        console.log("Here: ", services_rows[idx].service_price);
        sub_total = services_rows[idx].service_price * parseInt(value);
        services_rows[idx].service_sub_total = sub_total;
      }
      services_rows[idx][name] = value;
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const services_rows = [...prevState.services_rows];
        if (services_rows[idx]) {
          services_rows[idx][name] = value;
        }
      });
    }

    this.setState({
      services_rows,
      isBlocking: false,
    });
    if (name === "service_quantity") {
      this.calculateTotalRate();
    }
    console.log("services_rows: ", this.state.services_rows);
    //
  };

  calculateTotalRate = async () => {
    const { services_rows, discount_value_total } = this.state;

    console.log("calculateTotalRate: ", services_rows);
    let total = 0;

    if (services_rows && services_rows.length > 0) {
      for (let i = 0; i < services_rows.length; i++) {
        if (services_rows[i].service_checked === true) {
          total =
            total +
            parseFloat(services_rows[i].service_price) *
              parseInt(services_rows[i].service_quantity);
        }
      }
    }
    let with_discount = total - parseFloat(discount_value_total);
    //Calculate Tax
    let tax_value_total = this.state.tax_value_total;
    with_discount = with_discount + parseFloat(tax_value_total);
    //Calculate Shipping
    let shipping_value_total = this.state.shipping_value_total;
    with_discount = with_discount + parseFloat(shipping_value_total);

    //Calculate Shipping and Discount

    //with_discount = with_discount + total_tax;
    console.table({
      with_discount_shipping_tax: with_discount,
      discount_value_total: discount_value_total,
      tax_value_total: tax_value_total,
      shipping_value_total: shipping_value_total,
    });

    this.setState({
      service_grand_total:
        with_discount && with_discount > 0 ? with_discount.toFixed(2) : "0.00",
      service_grand_sub_total: total && total > 0 ? total.toFixed(2) : "0.00",
    });
  };

  updateSignatureType = (type) => {
    this.setState({
      signature_type: type,
    });
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  openServices = () => {
    window.$("#modalFormServices").modal("show");
  };

  closeServices = () => {
    window.$("#modalFormServices").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content rfq_Block" id="rfq_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/" + auth.getResellerRegToken() + "/client/")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/" + auth.getResellerRegToken() + "/client/Rfqs")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        RFQ's
                      </Link>

                      <Typography color="textPrimary" className="">
                        New RFQ
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          <h3 className="image-side-txt">
                            {" "}
                            Request For Quotation
                          </h3>
                          <p
                            style={{
                              color: "rgb(99, 115, 129)",
                              marginTop: "25px",
                            }}
                            className="rfq_description"
                          >
                            {" "}
                            A Request for Quotation (RFQ) is a document or
                            formal process used in business and procurement to
                            solicit price quotes from potential suppliers or
                            vendors. It typically includes specific details
                            about the products or services required, the
                            quantity, quality standards, and delivery terms. The
                            primary purpose of an RFQ is to gather competitive
                            price proposals from multiple suppliers, enabling
                            organizations to make informed decisions and select
                            the most cost-effective solution that meets their
                            needs. Once the RFQ responses are received,
                            organizations can compare offers and negotiate terms
                            before making a purchasing decision. RFQs play a
                            crucial role in procurement, helping businesses save
                            costs and ensure they receive the best value for
                            their investments.
                          </p>
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert  mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.mcqLoader === true ? (
                            McqLoader()
                          ) : (
                            <div className="nk-stepper-step active">
                              <h5 className="title mb-3">General Assesment</h5>
                              <div className="row g-gs">
                                {this.rfq_questions_rows &&
                                this.rfq_questions_rows.length > 0 ? (
                                  <>
                                    {this.rfq_questions_rows.map(
                                      (rfq, bid) => (
                                        <div
                                          className="col-sm-6 mt-5"
                                          key={`question_${bid}`}
                                        >
                                          <div className="form-group">
                                            <label className="form-label">
                                              {bid + 1}. {rfq.question_title} {" "}
                                                  <sup className="text-danger">
                                                    *
                                                  </sup>
                                            </label>
                                          </div>
                                          {rfq.question_type === "radio" ? (
                                            <>
                                              {rfq.all_answers &&
                                              rfq.all_answers.length > 0 ? (
                                                <div className="form-pm-group">
                                                  <ul className="buysell-pm-list">
                                                    {rfq.all_answers.map(
                                                      (rfq_question, rid) => (
                                                        <li
                                                          className="buysell-pm-item"
                                                          key={`options_${rid}`}
                                                        >
                                                          <input
                                                            className="buysell-pm-control"
                                                            type="radio"
                                                            name={`selected_answer_${rfq.question_id}`}
                                                            id={`question_${rid}_${bid}`}
                                                            onChange={this.handleChangeRfq(
                                                              rid
                                                            )}
                                                            defaultChecked={
                                                              rfq_question.id ===
                                                              rfq[
                                                                `selected_answer_${rfq.question_id}`
                                                              ]
                                                                ? true
                                                                : false
                                                            }
                                                            defaultValue={
                                                              rfq_question.id
                                                            }
                                                          />
                                                          <label
                                                            className="buysell-pm-label"
                                                            htmlFor={`question_${rid}_${bid}`}
                                                          >
                                                            <span className="pm-name">
                                                              {
                                                                rfq_question.question_options
                                                              }
                                                            </span>
                                                            <span className="pm-icon">
                                                              <em className="icon ni ni-question"></em>
                                                            </span>
                                                          </label>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                              ) : null}
                                            </>
                                          ) : rfq.question_type ===
                                            "checkbox" ? (
                                            <>
                                              {rfq.all_answers &&
                                              rfq.all_answers.length > 0 ? (
                                                <div className="form-pm-group">
                                                  <ul className="buysell-pm-list">
                                                  {rfq.all_answers.map(
                                                    (rfq_question, cid) => (
                                                      <li
                                                          className="buysell-pm-item"
                                                          key={`options_${cid}`}
                                                        >
<input
                                                            type="checkbox"
                                                            className="buysell-pm-control"
                                                            name={`selected_answer_${rfq.question_id}`}
                                                            onChange={this.handleChangeRfqCheckboxes(
                                                              cid
                                                            )}
                                                            id={`question_${cid}_${bid}`}
                                                            defaultChecked={
                                                              rfq[
                                                                `selected_answer_${rfq.question_id}`
                                                              ].includes(
                                                                rfq_question.id
                                                              )
                                                                ? true
                                                                : false
                                                            }
                                                            defaultValue={
                                                              rfq_question.id
                                                            }
                                                          />
                                                          <label
                                                            className="buysell-pm-label"
                                                            htmlFor={`question_${cid}_${bid}`}
                                                          >
                                                            <span className="pm-name">
                                                            {
                                                              rfq_question.question_options
                                                            }
                                                            </span>
                                                            <span className="pm-icon">
                                                              <em className="icon ni ni-question"></em>
                                                            </span>
                                                          </label>
                                                        </li>
                                                      
                                                    )
                                                  )}
                                               
                                              
                                            </ul>
                                            </div>
                                            ) : null}
                                             </>
                                          ) : rfq.question_type ===
                                            "textfield" ? (
                                            <>
                                              <div className="form-group">
                                                <input
                                                  id={`question_0_${bid}`}
                                                  className="form-control form-control-lg"
                                                  name={`selected_answer_${rfq.question_id}`}
                                                  defaultValue=""
                                                  onChange={this.handleChangeRfq(
                                                    0
                                                  )}
                                                  type="text"
                                                  placeholder=""
                                                  maxLength={100}
                                                />
                                              </div>
                                            </>
                                          ) : rfq.question_type ===
                                            "textarea" ? (
                                              <div className="form-group">
                                              <div className="form-control-wrap">
                                                <textarea
                                                className="form-control form-control-lg"
                                                  id={`question_0_${bid}`}
                                                  name={`selected_answer_${rfq.question_id}`}
                                                  onChange={this.handleChangeRfq(
                                                    0
                                                  )}
                                                  defaultValue=""
                                                  placeholder=""
                                                  maxLength={500}
                                                ></textarea>
                                              </div>
                                            </div>
                                          ) : rfq.question_type ===
                                            "dropdown" ? (
                                            <>
                                              <div className="form-group">
                                                <div className="form-control-wrap">
                                                  <div className="form-control-select">
                                                    <select
                                                      className="form-control form-control-lg"
                                                      id={`question_0_${bid}`}
                                                      name={`selected_answer_${rfq.question_id}`}
                                                      label="Select an Option"
                                                      defaultValue=""
                                                      onChange={this.handleChangeRfq(
                                                        0
                                                      )}
                                                    >
                                                      <option
                                                        key="sets100"
                                                        value=""
                                                      >
                                                        Select an option{" "}
                                                      </option>
                                                      {rfq.all_answers.map(
                                                        (rfq_question, rid) => (
                                                          <option
                                                            key={`sets${rid}`}
                                                            value={
                                                              rfq_question.id
                                                            }
                                                          >
                                                            {
                                                              rfq_question.question_options
                                                            }
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      )
                                    )}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          )}
                          <div className="nk-stepper-step active row g-gs">
                            <div className="col-lg-12 mt-4 mt-2">
                              <div className="nk-block-head">
                                <div className="nk-block-head-content d-flex">
                                  <div
                                    className="col-md-8 col-sm-12 col-xs-12"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <h5 className="nk-block-title title">
                                      Additional Information{" "}
                                    </h5>
                                  </div>
                                  <div className="col-md-4  col-sm-12  col-xs-12 d-flex justify-content-end">
                                    <button
                                      type="button"
                                      className="btn btn-dim btn-outline-primary"
                                      onClick={this.openServices}
                                    >
                                      Add Product / Services
                                    </button>
                                  </div>

                                  <p>&nbsp;</p>
                                </div>
                              </div>
                            </div>
                            <div className="row g-3">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="rfq_comments"
                                  >
                                    <Tooltip
                                      title="Describe your complete requirements in request for quotation."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Describe Your Requirements <sup>*</sup>
                                  </label>
                                  <div className="form-control-wrap">
                                    <Editor
                                      id="rfq_comments"
                                      name="rfq_comments"
                                      apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                      initialValue={this.state.rfq_comments}
                                      selector="textarea#full-featured"
                                      init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                          "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                        ],
                                        toolbar:
                                          "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                      }}
                                      onEditorChange={this.handleEditorChange}
                                    />
                                    <span
                                      id="rfq_comments_error"
                                      class="invalid"
                                      style={{ color: "red" }}
                                    >
                                      {this.state.rfq_comments_error}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12 mt-4">
                                <div className="nk-block-head">
                                  <div className="nk-block-head-content">
                                    <h5 className="nk-block-title title">
                                      Attachement{" "}
                                      <Tooltip
                                        title="User requirement document"
                                        placement="right"
                                      >
                                        <em
                                          className="icon ni ni-info"
                                          style={{
                                            color: "#6576ff",
                                          }}
                                        ></em>
                                      </Tooltip>
                                    </h5>
                                    <p>&nbsp;</p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <div
                                    className="form-control-group"
                                    id="fileContainer"
                                  >
                                    <input
                                      type="file"
                                      id="add_picture"
                                      className="file-block"
                                      name="add_picture"
                                      defaultValue={this.state.add_picture}
                                      accept=".png, .jpg, .jpeg, .pdf, .doc, docx, .xlx, .xlxs, .csv"
                                      onChange={this.handleFileChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <ul
                            className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end"
                            style={{ marginBottom: "50px" }}
                          >
                            <li className="step-next">
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                onClick={this.submitRfq}
                              >
                                Submit RFQ
                              </button>
                            </li>
                          </ul>
                          {/* SERVICES MODEL */}
                          <div className="modal fade" id="modalFormServices">
                            <div
                              className="modal-dialog modal-dialog-top modal-xl"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Add product / service
                                  </h5>
                                  <a
                                    onClick={() => this.closeServices()}
                                    style={{ cursor: "pointer" }}
                                    className="close"
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </a>
                                </div>
                                <div className="modal-body">
                                  <div className="row g-gs">
                                    <div className="col-sm-8">
                                      <div className="form-group">
                                        <div className="form-label-group">
                                          <label className="form-label">
                                            Select product and add
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <div className="form-control-group">
                                          <MutextField
                                            id="select_service"
                                            name="select_service"
                                            select
                                            label="Select Product"
                                            value={this.state.select_service}
                                            onChange={this.handleChange}
                                            SelectProps={{
                                              native: true,
                                            }}
                                            helperText="Please select a product from available products and add."
                                            variant="outlined"
                                            fullWidth
                                          >
                                            <option value="default_option">
                                              Select product / service
                                            </option>
                                            {this.state.services_rows &&
                                            this.state.services_rows.length >
                                              0 ? (
                                              <>
                                                {this.state.services_rows.map(
                                                  (service, sid) => (
                                                    <>
                                                      {service.service_checked ===
                                                      false ? (
                                                        <option
                                                          key={`sets${sid}`}
                                                          value={
                                                            service.service_id
                                                          }
                                                        >
                                                          {
                                                            service.service_title
                                                          }
                                                        </option>
                                                      ) : null}
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : null}
                                          </MutextField>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <div className="form-group">
                                        <label className="form-label">
                                          &nbsp;
                                        </label>
                                        <div className="form-control-wrap">
                                          <button
                                            type="button"
                                            className="btn btn-lg btn-primary"
                                            onClick={() => this.addNewService()}
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row g-gs">
                                    <div className="col-sm-12">
                                      <div className="invoice-bills">
                                        <div className="table-responsive">
                                          <table className="table table-striped table-condensed">
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Product Name
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Qty
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Unit
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.services_rows &&
                                              this.state.services_rows.length >
                                                0 ? (
                                                <>
                                                  {this.state.services_rows.map(
                                                    (service, sid) => (
                                                      <>
                                                        {service.service_checked ===
                                                        true ? (
                                                          <tr key={`ser${sid}`}>
                                                            <td>
                                                              {
                                                                service.service_title
                                                              }
                                                            </td>

                                                            <td>
                                                              <div className="form-control-wrap">
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  id="service_quantity"
                                                                  name="service_quantity"
                                                                  onChange={this.handleChangeServices(
                                                                    sid
                                                                  )}
                                                                  placeholder=""
                                                                  style={{
                                                                    width:
                                                                      "60px",
                                                                  }}
                                                                  value={
                                                                    service.service_quantity
                                                                  }
                                                                  required
                                                                />
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="form-control-wrap">
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  id="service_unit"
                                                                  name="service_unit"
                                                                  onChange={this.handleChangeServices(
                                                                    sid
                                                                  )}
                                                                  placeholder=""
                                                                  onInput={(
                                                                    e
                                                                  ) => {
                                                                    e.target.value =
                                                                      e.target.value.replace(
                                                                        /[^a-zA-Z0-9]/gi,
                                                                        ""
                                                                      );
                                                                  }}
                                                                  style={{
                                                                    width:
                                                                      "60px",
                                                                  }}
                                                                  value={
                                                                    service.service_unit
                                                                  }
                                                                  required
                                                                />
                                                              </div>
                                                            </td>
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "left",
                                                              }}
                                                            >
                                                              <a
                                                                onClick={() =>
                                                                  this.removeService(
                                                                    service.service_id
                                                                  )
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  color: "red",
                                                                }}
                                                              >
                                                                <em class="icon ni ni-trash-fill"></em>
                                                              </a>
                                                            </td>
                                                          </tr>
                                                        ) : null}
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              ) : null}
                                            </tbody>
                                          </table>
                                        </div>
                                        
                                        <div class="example-alert mt-5 mb-2">
                                          <div class="alert alert-light">
                                            <strong>NOTE:</strong> After adding
                                            the product, if you're unsure about
                                            the quantity, you can leave it as 0.
                                          </div>
                                        </div>
                                        {this.state.services_rows &&
                                        this.state.services_rows.length > 0 ? (
                                          <div class="col-md-12 mt-3 mb-2 d-flex justify-content-end">
                                            <button
                                              type="button"
                                              className="btn btn-light"
                                              onClick={() =>
                                                this.closeServices()
                                              }
                                            >
                                              Done
                                            </button>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRfq);
