import { Link } from "react-router-dom";
import { LOGO } from "./../../config/config";
import $ from "jquery";
import HelperClass from "./../../config/helperClass";
const auth = new HelperClass();
const leftSideBar = (props) => {
  console.log("PROPS: ", props);
  function classCheck() {
    var menu_active = $(this);
    var menu_items = $("#reportmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function aClassCheck() {
    var menu_active = $(this);
    var menu_items = $("#agreementmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function billingClassCheck() {
    var menu_active = $(this);
    var menu_items = $("#billingmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function reportsClassCheck() {
    var menu_active = $(this);
    var menu_items = $("#reportsmenu");
    if (menu_items.hasClass("active")) {
      menu_items.removeClass("active");
      menu_items.removeClass("current-page");
    } else {
      menu_items.addClass("active");
      menu_items.addClass("current-page");
    }
  }

  function activeCheck() {
    var menu_active = $(this);
    console.log("menu active has: ", menu_active);
    if (menu_active.hasClass("active")) {
      $(this).removeClass("active");
      $(this).removeClass("current-page");
    } else {
      $(this).addClass("active");
      $(this).addClass("current-page");
    }
  }

  const pageviewcheck = () => {
    var pageview = props.mainProps.view;
    if (pageview == "editor") {
      var result = '{{display:"none"}}';
    } else {
      var result = "{{}}";
    }
    return result;
  };

  const dashboardLeft = () => {
    props.mainProps.history.push(
      "/" + auth.getResellerRegToken() + "/client/dashboard"
    );
  };

  const clientsLeft = () => {
    props.mainProps.history.push("/clients");
  };

  const servicesLeft = () => {
    props.mainProps.history.push(
      "/" + auth.getResellerRegToken() + "/client/services"
    );
  };
  const supportLeft = () => {
    props.mainProps.history.push(
      "/" + auth.getResellerRegToken() + "/client/help"
    );
  };

  const agentsLeft = () => {
    props.mainProps.history.push("/agents");
  };

  const sharelinksLeft = () => {
    props.mainProps.history.push("/sharelinks");
  };

  const shareLinkSetting = () => {
    props.mainProps.history.push("/sharelinks/sharelink_settings");
  };

  const reportsGetDailyOrigStats = () => {
    props.mainProps.history.push("/reports/get_daily_orig_stats");
  };

  const reportsGetDailyTermStats = () => {
    props.mainProps.history.push("/reports/get_daily_term_stats");
  };

  const reportsGetDailySmsStats = () => {
    props.mainProps.history.push("/reports/get_daily_sms_stats");
  };

  const reportsGetDailyDidStats = () => {
    props.mainProps.history.push("/reports/get_daily_did_stats");
  };

  const reportsGetDailyRevenue = () => {
    props.mainProps.history.push("/reports/get_daily_revenue");
  };

  const reportsGetAggOrigStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_orig_stats");
  };

  const reportsGetAggTermStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_term_stats");
  };

  const reportsGetAggSmsStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_sms_stats");
  };

  const reportsGetAggDidStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_did_stats");
  };

  const reportsGetAggRevenueStats = () => {
    props.mainProps.history.push("/reports/get_aggregate_revenue_stats");
  };

  const analyticsLeft = () => {
    props.mainProps.history.push("/analytics");
  };

  const agreementsLeft = () => {
    props.mainProps.history.push(
      "/" + auth.getResellerRegToken() + "/client/agreements"
    );
  };

  const unsignedagreementsLeft = () => {
    props.mainProps.history.push(
      "/" + auth.getResellerRegToken() + "/client/agreements/unsignedAgreements"
    );
  };

  const cmsLeft = () => {
    props.mainProps.history.push("/cms");
  };

  const domainsLeft = () => {
    props.mainProps.history.push("/domains");
  };

  const themesLeft = () => {
    props.mainProps.history.push("/themes");
  };

  const upgradesLeft = () => {
    props.mainProps.history.push("/upgrades");
  };

  const navigateLink = (link) => {
    props.mainProps.history.push("/" + auth.getResellerRegToken() + "/" + link);
  };

  const navigateAdmin = (link) => {
    props.mainAdminProps.history.push("/" + link);
  };

  const routingLeft = () => {
    props.mainProps.history.push("/upgrades");
  };

  return (
    <div className="nk-sidebar nk-sidebar-fixed" data-content="sidebarMenu">
      <div
        className="nk-sidebar-element nk-sidebar-head"
        style={{
          backgroundColor: "white",
          height: "68px",
          borderRight: "1px solid #e8ecec",
        }}
      >
        <div className="nk-sidebar-brand">
          <div className="logo-link nk-sidebar-logo">
            {auth.getResellerLogo() !== "" ? (
              <>
                <img
                  className="logo-light logo-img"
                  src={auth.getResellerLogo()}
                  alt="logo"
                />
                <img
                  className="logo-dark logo-img"
                  src={auth.getResellerLogo()}
                  alt="logo-dark"
                  style={{ marginLeft: "0" }}
                />
              </>
            ) : (
              <>
                <img className="logo-light logo-img" src={LOGO} alt="logo" />
                <img
                  className="logo-dark logo-img"
                  src={LOGO}
                  alt="logo-dark"
                  style={{ marginLeft: "0" }}
                />
              </>
            )}
          </div>
        </div>
        <div className="nk-menu-trigger mr-n2">
          <div
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left"></em>
          </div>
        </div>
      </div>
      <div
        className="nk-sidebar-element"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              <li className="nk-menu-heading">
                <h3
                  className="overline-title text-primary-alt"
                  style={{ color: "#6576ff" }}
                >
                  Clients Portal
                </h3>
              </li>
              <li className="nk-menu-heading">
                <h3
                  className="overline-title text-primary-alt"
                  style={{ color: "#202223" }}
                >
                  {auth.getCompName()}
                </h3>
              </li>
              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link className="nk-menu-link" onClick={() => dashboardLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-dashboard"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    Dashboard
                  </span>
                </Link>
              </li>
              <li
                className="nk-menu-item has-sub"
                onClick={() => aClassCheck()}
                id="agreementmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-file-docs"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Agreements
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => agreementsLeft()}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-check-round-cut"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Signed Agreements
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => unsignedagreementsLeft()}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-na"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Unsigned Agreements
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link
                  className="nk-menu-link"
                  onClick={() => navigateLink("client/CreditApp")}
                >
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-coin"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Credit Application
                  </span>
                </Link>
              </li>
              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link
                  className="nk-menu-link"
                  onClick={() => navigateLink("client/KycForm")}
                >
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-user-list-fill"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    KYC Form
                  </span>
                </Link>
              </li>
              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link className="nk-menu-link" onClick={() => navigateLink("client/SalesOrders")}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-list-round"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Orders
                  </span>
                </Link>
              </li>
              <li
                className="nk-menu-item has-sub"
                onClick={() => billingClassCheck()}
                id="billingmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-file-docs"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Quotations
                  </span>
                </div>
                <ul className="nk-menu-sub">
                <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/Quotations")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-file-docs"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage Quotations
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/Rfqs")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-align-justify"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Manage RFQ's
                      </span>
                    </Link>
                  </li>
                  
                  
                </ul>
              </li>

              

              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link className="nk-menu-link" onClick={() => servicesLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-list-round"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Products
                  </span>
                </Link>
              </li>

              <li
                className="nk-menu-item has-sub"
                onClick={() => billingClassCheck()}
                id="billingmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-cc"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Billing
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/BillingInformation")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-align-justify"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Billing Information
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/Invoices")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-file-docs"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Invoices
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/PaymentHistory")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-histroy"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Payment History
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/Pricing")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-template"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Pricing
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className="nk-menu-item has-sub"
                onClick={() => reportsClassCheck()}
                id="reportsmenu"
              >
                <div className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-bar-chart"></em>
                  </span>
                  <span
                    className="nk-menu-text"
                    style={{
                      fontSize: "1.1rem",
                      marginLeft: "5%",
                      cursor: "pointer",
                    }}
                  >
                    Reports
                  </span>
                </div>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/Cdr")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-file-docs"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        CDR
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/SmsRecords")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-chat-circle"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        SMS Records
                      </span>
                    </Link>
                  </li>

                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/MouStats")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-file-docs"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        MOU Stats
                      </span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      className="nk-menu-link"
                      onClick={() => navigateLink("client/BalanceUsage")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-money"></em>
                      </span>
                      <span
                        className="nk-menu-text"
                        style={{ color: "#202223" }}
                      >
                        Balance Usage
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nk-menu-item" onClick={() => activeCheck()}>
                <Link
                  className="nk-menu-link"
                  onClick={() => navigateLink("client/SubClients")}
                >
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-users"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Sub Clients
                  </span>
                </Link>
              </li>
              <li className="nk-menu-item" key="support-1">
                <Link className="nk-menu-link" onClick={() => supportLeft()}>
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-help"></em>
                  </span>
                  <span className="nk-menu-text" style={{ fontSize: "1.1rem" }}>
                    Support
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default leftSideBar;
