import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import $ from "jquery";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./styles.css";
//import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import { DataList } from "../../../components/ContentLoaders/newLoaders/DataList";

import Services from "../Services/index";
import Analytics from "../Analytics/index";

import { addSettings, addMsaInfo } from "../../../actions";
import { COMPANY_NAME } from "../../../config/config";
import {
  listAvailableServices,
  getDashboardCounts,
  getWelcomeContent,
  getServiceDetails,
  getClientKycDetail,
  addServiceWithProjection,
} from "./../../../config/client_api_calls";
import { dateString, dateFirstString } from "./../../../config/config";

import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
  KYC_URL,
} from "./../../../config/config";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
const auth = new HelperClass();

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      serviceData: [],
      tableLoader: true,
      dataTextLoader: true,
      totalRegistered: 0,
      totalActive: 0,
      monthlyBills: "0.00",
      paidBills: "0.00",
      welcome_content: "",
      kyc_completed: 100,
      kyc_notification: "",
      kyc_link: "",
      service_name: "",
      errorMessageForcast: "",
      successMessageForcast: "",
      monthly_usage: 50000000,
      numbers_quantity: 1,
      usage_type: "Inbound Notifications",
      estimated_sessions: 10000,
      capture_projection: "",
      service_id: "",
      service_type: "",
      submitButton: false,
      columnServices: [
        { name: "Id" },
        { name: "Title" },

        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Marketing",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultMarketing(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Order",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Product Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>Product Details</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  setMarkUp = () => {
    //window.$("#modalForm").modal("show");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  async componentDidMount() {
    console.log("dateFirstString: ", dateFirstString);
    await this.setWelcomeContent();
    await this.setServiceList();
    await this.getKycDetails();
  }

  defaultMarketing = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value.split("###");
    let marketing_content = is_default[0];
    let marketing_link = is_default[1];
    if (marketing_content === "link") {
      return (
        <a href={marketing_link} target="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Product Info
          </span>
        </a>
      );
    } else if (marketing_content === "pdf") {
      return (
        <a href={marketing_link} target="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em> View PDF
          </span>
        </a>
      );
    } else {
      return "";
    }
  };

  defaultText = (value, tableMeta) => {
    let { kyc_completed } = this.state;
    //console.log("tableMeta.rowData: ", tableMeta);
    let is_default = tableMeta.rowData[4];
    let service_id = tableMeta.rowData[0];
    let service_name = tableMeta.rowData[1];
    return "---";
    /*return (
      <>
        {is_default === "Yes" ? (
          <a
            onClick={() =>
              this.signService(service_id, is_default, service_name)
            }
            style={{ cursor: "pointer" }}
          >
            <span className="badge badge-outline-success">
              <em className="icon ni ni-cart"></em> Order Now
            </span>
          </a>
        ) : (
          <a
            onClick={() =>
              this.signService(service_id, is_default, service_name)
            }
            style={{ cursor: "pointer" }}
          >
            <span className="badge badge-outline-success">
              <em className="icon ni ni-cart"></em> Order Now
            </span>
          </a>
        )}
      </>
    );*/
  };

  signService = async (service_id, is_default, service_name) => {
    let { kyc_completed } = this.state;
    this.setState({
      service_name: service_name,
      service_id: service_id,
      submitButton: false,
    });
    console.log(
      "service_name: " +
        service_name +
        " service_id: " +
        service_id +
        " is_default" +
        is_default +
        " kyc_completed: " +
        kyc_completed
    );
    let service_details = "";
    let capture_projection = "";
    let rate = "";
    let mrc = "";
    let sms_in = "";
    let sms_out = "";
    let service_type = "";
    if (is_default === "No") {
      service_details = await this.serviceDetails(service_id);

      capture_projection = service_details[0][33];
      rate = service_details[0][7];
      mrc = service_details[0][5];
      sms_in = service_details[0][10];
      sms_out = service_details[0][11];
      service_type = service_details[0][6];
      console.log("service_details : ", service_details[0][33]);
      this.setState({
        capture_projection: capture_projection,
        service_type: service_type,
      });
      window.$("#modalOrderService").modal("show");
    } else {
      if (kyc_completed === 100) {
        service_details = await this.serviceDetails(service_id);

        capture_projection = service_details[0][33];
        rate = service_details[0][7];
        mrc = service_details[0][5];
        sms_in = service_details[0][10];
        sms_out = service_details[0][11];
        service_type = service_details[0][6];
        this.setState({
          capture_projection: capture_projection,
          service_type: service_type,
        });
        window.$("#modalOrderService").modal("show");
      } else {
        window.$("#modalAlertCompleteKyc").modal("show");
      }
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  serviceDetails = async (service_id) => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getServiceDetails(
      auth.getClientAccount(),
      auth.getClientToken(),
      service_id
    );
    //console.log("serviceDetails: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      return servicesResponce.data.data;
    }
    return [];
  };

  formSaveWithProjection = async () => {
    let request_type = "with_projection";
    let {
      service_id,
      monthly_usage,
      numbers_quantity,
      usage_type,
      estimated_sessions,
      service_type,
    } = this.state;
    this.setState({
      submitButton: true,
    });
    const servicesResponce = await addServiceWithProjection(
      auth.getClientAccount(),
      auth.getClientToken(),
      service_id,
      monthly_usage,
      numbers_quantity,
      usage_type,
      estimated_sessions,
      service_type,
      request_type
    );
    console.log("formSaveWithProjection: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessageForcast: "There is some error while adding the service.",
        successMessageForcast: "",
        submitButton: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessageForcast:
          "Service Successfully Added. This page will automatically refresh in a moment.",
        errorMessageForcast: "",
      });
      setTimeout(() => {
        this.setState({ errorMessageForcast: "", successMessageForcast: "" });
        window.location.reload();
      }, 5000);
    } else {
      this.setState({
        errorMessageForcast:
          "There is some error while adding the service data.",
        successMessageForcast: "",
        submitButton: false,
      });
      //window.location.replace("/error");
    }
  };

  formSaveWithoutProjection = async () => {
    let request_type = "without_projection";
    let {
      service_id,
      monthly_usage,
      numbers_quantity,
      usage_type,
      estimated_sessions,
      service_type,
    } = this.state;
    this.setState({
      submitButton: true,
    });
    const servicesResponce = await addServiceWithProjection(
      auth.getClientAccount(),
      auth.getClientToken(),
      service_id,
      monthly_usage,
      numbers_quantity,
      usage_type,
      estimated_sessions,
      service_type,
      request_type
    );
    console.log("formSaveWithProjection: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessageForcast: "There is some error while adding the service.",
        successMessageForcast: "",
        submitButton: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessageForcast:
          "Service Successfully Added. This page will automatically refresh in a moment.",
        errorMessageForcast: "",
      });
      setTimeout(() => {
        this.setState({ errorMessageForcast: "", successMessageForcast: "" });
        window.location.reload();
      }, 5000);
    } else {
      this.setState({
        errorMessageForcast:
          "There is some error while adding the service data.",
        successMessageForcast: "",
        submitButton: false,
      });
      //window.location.replace("/error");
    }
  };

  showDetails = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getServiceDetails(
      auth.getClientAccount(),
      auth.getClientToken(),
      service_id
    );
    console.log("getAgentService: ", servicesResponce.data.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetailDash").modal("show");
  };

  modalHide = () => {
    window.$("#modalFormDetailDash").modal("hide");
  };

  viewModal = async (value, tableMeta) => {
    //console.log("[viewModal]value: ", value);
    // console.log("[viewModal]tableMeta: ", tableMeta.rowIndex);
  };

  setGraphs = async () => {
    let getCountsInfo = await getDashboardCounts(
      auth.getClientAccount(),
      auth.getClientToken(),
      dateFirstString,
      dateString
    );
    //console.log("Totals: ", getCountsInfo.data.data);
    if (
      getCountsInfo.data.status === 403 ||
      getCountsInfo.data.errors === "authentication missing" ||
      getCountsInfo.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (getCountsInfo.data.status === 404) {
      // window.location.replace("/error");
    } else if (
      getCountsInfo.data.status === 200 &&
      getCountsInfo.data.message === "success"
    ) {
      this.setState({
        totalRegistered: getCountsInfo.data.data["total_registered"],
        totalActive: getCountsInfo.data.data["total_active"],
        monthlyBills: getCountsInfo.data.data["monthly_bills"],
        paidBills: getCountsInfo.data.data["paid_bills"],
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getKycDetails = async () => {
    const servicesResponce = await getClientKycDetail(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("KYC Details: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 406) {
      //window.location.replace("/error");
      this.setState({
        kyc_completed: 0,
        kyc_notification: "",
      });
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        kyc_completed: 0,
        kyc_notification: "",
      });
    } else if (servicesResponce.data.status === 200) {
      let completed_percentage =
        (100 / servicesResponce.data.steps_allowed) *
        servicesResponce.data.steps_completed;
      this.setState({
        kyc_completed: completed_percentage,
        kyc_notification: "",
        kyc_link: KYC_URL + "client/register/" + servicesResponce.data.kyc_key,
      });
    } else {
      this.setState({
        kyc_completed: 0,
        kyc_notification: "",
      });
      //window.location.replace("/error");
    }
  };

  setServiceList = async () => {
    const servicesResponce = await listAvailableServices(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("listAvailableServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  setWelcomeContent = async () => {
    const welcomeResponse = await getWelcomeContent(
      auth.getClientAccount(),
      auth.getClientToken()
    );

    if (
      welcomeResponse.data.status === 403 ||
      welcomeResponse.data.errors === "authentication missing" ||
      welcomeResponse.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (welcomeResponse.data.status === 404) {
      this.setState({
        welcome_content: "",
        dataTextLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      welcomeResponse.data.status === 200 &&
      welcomeResponse.data.message === "success"
    ) {
      if (welcomeResponse.data.data && welcomeResponse.data.data !== "") {
        let welcome_content = welcomeResponse.data.data.replace(
          "[[Company_Name]]",
          auth.getResellerRegTitle()
        );
        welcome_content = welcome_content.replace(
          "[[Company_Email]]",
          "support email"
        );
        this.setState({
          welcome_content: welcome_content,
          dataTextLoader: false,
        });
      } else {
        this.setState({
          welcome_content: "",
          dataTextLoader: false,
        });
      }
    } else {
      this.setState({
        welcome_content: "",
        dataTextLoader: false,
      });
      //window.location.replace("/error");
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("handleChange:", name + " " + value);
  };

  render() {
    /*$("#Services_Block.nk-content").css("padding", "0px");
    $("#Services_Block.nk-content .container-fluid").css("padding-left", "0px");
    $("#Services_Block.nk-content .container-fluid").css(
      "padding-right",
      "0px"
    );*/

    $("#Analytics_Block.nk-content").css("padding", "0px");
    $("#Analytics_Block.nk-content").css("padding-bottom", "20px");
    $("#Analytics_Block.nk-content .container-fluid").css(
      "padding-left",
      "0px"
    );
    $("#Analytics_Block.nk-content .container-fluid").css(
      "padding-right",
      "0px"
    );
    $("#HeadContent").hide();
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* {this.header()} */}
              {this.state.kyc_completed !== 100 ? (
                <div class="example-alert mb-5">
                  <div
                    class="alert alert-warning alert-icon"
                    style={{ color: "#9d7a09", fontWeight: "700" }}
                  >
                    {auth.getUserTypeClient() === "Super" ? (
                      <>
                        <em class="icon ni ni-alert-circle"></em> Your KYC
                        profile is currently incomplete. To unlock additional
                        services, kindly{" "}
                        <a href={this.state.kyc_link} target="_blank">
                          <span> Click Here </span>
                        </a>{" "}
                        to finalize and update your KYC information.
                      </>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="nk-block">
                {/* FIRST ROW START */}
                {this.state.dataTextLoader === true ? (
                  <div className="row g-gs" style={{ clear: "both" }}>
                    <div className="col-lg-12 col-xxl-12">
                      <div
                        className="card card-bordered"
                        style={{ clear: "both" }}
                      >
                        <div className="card-inner">{DataList()}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.welcome_content &&
                    this.state.welcome_content !== "" ? (
                      <div className="row g-gs" style={{ clear: "both" }}>
                        <div className="col-lg-12 col-xxl-12">
                          <div
                            className="card card-bordered"
                            style={{ clear: "both" }}
                          >
                            <div className="card-inner">
                              <div className="card-title-group pb-3 g-2">
                                <div className="card-title card-title">
                                  <h4 className="title">Client Welcome Page</h4>
                                  <p>Welcome to {auth.getCompNameClient()}</p>
                                </div>
                              </div>

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: this.state.welcome_content,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
                <br />
                <br />
                {/* <Analytics from="dashboard" /> */}
                <Services from="dashboard" />

                <div
                  className="nk-block-head-content"
                  style={{ marginTop: "28px", marginBottom: "28px" }}
                >
                  <h3 className="nk-block-title page-title">
                    <PageTitle
                      name="Available Products"
                      icon="icon ni ni-card-view"
                    />
                  </h3>
                </div>

                {this.state.tableLoader === true ? (
                  <div className="row g-gs" style={{ clear: "both" }}>
                    <div className="col-lg-12 col-xxl-12">
                      <div
                        className="card card-bordered"
                        style={{ clear: "both" }}
                      >
                        <div className="card-inner" style={{ padding: "0px" }}>
                          {TableLoader()}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <DataTable
                    columns={this.state.columnServices}
                    tableData={this.state.tableData}
                    title="Available Products"
                  />
                )}

                {/*  DETAILS MODAL*/}
                <div
                  className="modal fade zoom"
                  tabIndex="-1"
                  id="modalFormDetailDash"
                >
                  <div
                    className="modal-dialog modal-xl modal-dialog-top"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <div
                          className="modal-title"
                          style={{ textAlign: "center", fontSize: "20px" }}
                        >
                          <strong>
                            <em className="icon ni ni-list-round"></em> Product
                            Details
                          </strong>
                        </div>
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                      </div>

                      <div className="modal-body">
                        {/* NRC DIVS START */}

                        {this.state.serviceData &&
                        this.state.serviceData.length > 0 ? (
                          <>
                            <div>
                              {this.state.serviceData.map((services, idx) => (
                                <div
                                  className="card card-bordered"
                                  key={`servi${idx}`}
                                >
                                  <div className="card-inner-group">
                                    {services[6] === "did" ||
                                    services[6] === "tfn" ||
                                    services[6] === "teams" ? (
                                      <div className="card-inner">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                            {services[1]}
                                            {/*<span className="badge badge-dim badge-primary badge-pill">
                                              {services[6] === "did"
                                                ? "DID Origination"
                                                : ""}
                                              {services[6] === "tfn"
                                                ? "Toll Free Origination"
                                                : ""}
                                              {services[6] === "term"
                                                ? "Voice Termination"
                                                : ""}
                                              {services[6] === "teams"
                                                ? "Microsoft Teams UCAAS"
                                                : ""}
                                              {services[6] === "ivr"
                                                ? "Voyce Admins"
                                                : ""}
                                              {services[6] === "tfn_term"
                                                ? "Toll Free Termination"
                                                : ""}
                                              {services[6] === "az_term"
                                                ? "International A-Z Termination"
                                                : ""}
                                              {services[6] === "live_agent"
                                                ? "Live Agent"
                                                : ""}
                                              {services[6] === "sms"
                                                ? "SMS Inbound / Outbound"
                                                : ""}
                                    </span>*/}
                                          </h6>
                                        </div>

                                        <div className="sp-plan-desc sp-plan-desc-mb">
                                          <ul className="row gx-1">
                                            <li className="col-sm-4">
                                              <p>
                                                <span className="text-soft">
                                                  NRC
                                                  <Tooltip
                                                    title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                    placement="top"
                                                  >
                                                    <em
                                                      className="icon ni ni-info"
                                                      style={{
                                                        color: "#6576ff",
                                                      }}
                                                    ></em>
                                                  </Tooltip>
                                                </span>{" "}
                                                ${services[4]}
                                              </p>
                                            </li>
                                            <li className="col-sm-4">
                                              <p>
                                                <span className="text-soft">
                                                  MRC{" "}
                                                  <Tooltip
                                                    title="Monthly Recurring Charge - what you pay each month for the service."
                                                    placement="top"
                                                  >
                                                    <em
                                                      className="icon ni ni-info"
                                                      style={{
                                                        color: "#6576ff",
                                                      }}
                                                    ></em>
                                                  </Tooltip>
                                                </span>{" "}
                                                ${services[5]}
                                              </p>
                                            </li>
                                            {services[23] !== "ratedeck" ? (
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    Rate Per Minute{" "}
                                                    <Tooltip
                                                      title="Rate assigned per minute."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[7]}
                                                </p>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </div>
                                      </div>
                                    ) : null}
                                    {services[6] === "term" ||
                                    services[6] === "ivr" ||
                                    services[6] === "live_agent" ||
                                    services[6] === "tfn_term" ||
                                    services[6] === "az_term" ||
                                    services[6] === "generic" ? (
                                      <div className="card-inner">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                            {services[1]}{" "}
                                            {/*<span className="badge badge-dim badge-primary badge-pill">
                                              {services[6] === "did"
                                                ? "DID Origination"
                                                : ""}
                                              {services[6] === "tfn"
                                                ? "Toll Free Origination"
                                                : ""}
                                              {services[6] === "term"
                                                ? "DID Termination"
                                                : ""}
                                              {services[6] === "teams"
                                                ? "Microsoft Teams UCAAS"
                                                : ""}
                                              {services[6] === "ivr"
                                                ? "Voyce Admins"
                                                : ""}
                                              {services[6] === "tfn_term"
                                                ? "Toll Free Termination"
                                                : ""}
                                              {services[6] === "az_term"
                                                ? "International A-Z Termination"
                                                : ""}
                                              {services[6] === "live_agent"
                                                ? "Live Agent"
                                                : ""}
                                              {services[6] === "sms"
                                                ? "SMS Inbound / Outbound"
                                                : ""}
                                              {services[6] === "generic"
                                                ? "General Products & Services"
                                                : ""}
                                              {services[23] === "ratedeck"
                                                ? " ( Rate Deck )"
                                                : ""}
                                    </span>*/}
                                          </h6>
                                        </div>

                                        <div className="sp-plan-desc sp-plan-desc-mb">
                                          <ul className="row gx-1">
                                            {services[23] === "ratedeck" ? (
                                              <></>
                                            ) : (
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    {services[6] === "generic"
                                                      ? "Price"
                                                      : "Rate Per Minute"}{" "}
                                                    <Tooltip
                                                      title={
                                                        services[6] ===
                                                        "generic"
                                                          ? "Rate assigned"
                                                          : "Rate assigned per minute"
                                                      }
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {Number(services[7]).toFixed(
                                                    2
                                                  )}
                                                </p>
                                              </li>
                                            )}
                                          </ul>
                                        </div>
                                        {services[6] === "generic" ? (
                                          <>
                                            <div
                                              className="sp-plan-head"
                                              style={{
                                                marginTop: "15px",
                                                borderTop: "1px solid #f6f6f6",
                                                paddingTop: "15px",
                                              }}
                                            >
                                              <h6 className="title">
                                                Other Service Rates
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <table className="table">
                                                <thead className="thead-light">
                                                  <tr>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">
                                                      Charge Type
                                                    </th>
                                                    <th scope="col">
                                                      Cycle
                                                    </th>
                                                    <th scope="col">Price</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {services[34].map(
                                                    (miscrate, idx) => (
                                                      <tr key={`mis${idx}`}>
                                                        <td>
                                                          {
                                                            miscrate.generic_title
                                                          }
                                                        </td>
                                                        <td>
                                                          {miscrate.pay_type ===
                                                          "recurring"
                                                            ? "Recurring"
                                                            : ""}

                                                          {miscrate.pay_type ===
                                                          "one_time"
                                                            ? "One Time"
                                                            : ""}
                                                        </td>
                                                        <td>
                                                          {miscrate.pay_type ===
                                                          "recurring" ? (
                                                            <>
                                                              {miscrate.generic_type ===
                                                              "per_minute"
                                                                ? "Per Minute"
                                                                : ""}
                                                              {miscrate.generic_type ===
                                                              "per_hour"
                                                                ? "Per Hour"
                                                                : ""}
                                                              {miscrate.generic_type ===
                                                              "per_second"
                                                                ? "Per Second"
                                                                : ""}
                                                              {miscrate.generic_type ===
                                                              "per_day"
                                                                ? "Per Day"
                                                                : ""}
                                                              {miscrate.generic_type ===
                                                              "per_month"
                                                                ? "Per Month"
                                                                : ""}
                                                              {miscrate.generic_type ===
                                                              "per_year"
                                                                ? "Per Year"
                                                                : ""}
                                                            </>
                                                          ) : null}
                                                        </td>
                                                        <td>
                                                          {miscrate.generic_rate !==
                                                            "" &&
                                                          miscrate.generic_rate >
                                                            0 ? (
                                                            <>
                                                              $
                                                              {miscrate.generic_rate.toFixed(
                                                                2
                                                              )}
                                                            </>
                                                          ) : (
                                                            "$0.00"
                                                          )}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    ) : null}
                                    {services[6] === "sms" ? (
                                      <div className="card-inner">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                            {services[1]}{" "}
                                            <span className="badge badge-dim badge-primary badge-pill">
                                              New
                                            </span>
                                          </h6>
                                        </div>

                                        <div className="sp-plan-desc sp-plan-desc-mb">
                                          <ul className="row gx-1">
                                            <li className="col-sm-5">
                                              <p>
                                                <span className="text-soft">
                                                  SMS In{" "}
                                                  <Tooltip
                                                    title="Price applied to SMS In Services."
                                                    placement="top"
                                                  >
                                                    <em
                                                      className="icon ni ni-info"
                                                      style={{
                                                        color: "#6576ff",
                                                      }}
                                                    ></em>
                                                  </Tooltip>
                                                </span>{" "}
                                                ${services[10]}
                                              </p>
                                            </li>
                                            <li className="col-sm-5">
                                              <p>
                                                <span className="text-soft">
                                                  SMS Out{" "}
                                                  <Tooltip
                                                    title="Price applied to SMS Out Services."
                                                    placement="top"
                                                  >
                                                    <em
                                                      className="icon ni ni-info"
                                                      style={{
                                                        color: "#6576ff",
                                                      }}
                                                    ></em>
                                                  </Tooltip>
                                                </span>{" "}
                                                ${services[11]}
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ) : null}
                                    {services[6] === "did" ||
                                    services[6] === "tfn" ||
                                    services[6] === "teams" ? (
                                      <>
                                        {services[30] &&
                                        services[30].length > 0 ? (
                                          <div className="card-inner">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Porting Rates
                                              </h6>
                                            </div>

                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Portin Sell Rate
                                                    </span>{" "}
                                                    $
                                                    {services[30][0].portin_sell_rate.toFixed(
                                                      4
                                                    )}
                                                  </p>
                                                </li>
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Portin Sell Rate
                                                    </span>{" "}
                                                    $
                                                    {services[30][0].portout_sell_rate.toFixed(
                                                      4
                                                    )}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                    <div className="card-inner">
                                      {services[23] !== "ratedeck" ? (
                                        <>
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Cancellation Fee
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Cancellation Fee
                                                  </span>{" "}
                                                  {services[29] &&
                                                  services[29] !== "" ? (
                                                    <>
                                                      ${services[29].toFixed(2)}
                                                    </>
                                                  ) : (
                                                    "$0.00"
                                                  )}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </>
                                      ) : null}
                                      {services[23] === "ratedeck" ? (
                                        <>
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Rate Deck / Effective Date
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Rate Deck
                                                  </span>{" "}
                                                  {services[25] &&
                                                  services[25] !== "" ? (
                                                    <>
                                                      <a
                                                        onClick={() =>
                                                          this.downloadCsv(
                                                            services[25]
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="btn btn-outline-primary"
                                                      >
                                                        <em className="icon ni ni-download"></em>{" "}
                                                        Download Rate Deck
                                                      </a>
                                                    </>
                                                  ) : null}
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Effective Date
                                                  </span>{" "}
                                                  {services[24] &&
                                                  services[24] !== "" ? (
                                                    <>
                                                      {this.format_date(
                                                        services[24]
                                                      )}
                                                    </>
                                                  ) : null}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </>
                                      ) : null}
                                      {services[31] &&
                                      services[31].length > 0 ? (
                                        <>
                                          <div
                                            className="sp-plan-head"
                                            style={{
                                              marginTop: "15px",
                                              borderTop: "1px solid #f6f6f6",
                                              paddingTop: "15px",
                                            }}
                                          >
                                            <h6 className="title">
                                              Miscellaneous Rates
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <table className="table">
                                              <thead className="thead-light">
                                                <tr>
                                                  <th scope="col">Title</th>
                                                  <th scope="col">Rate Type</th>
                                                  <th scope="col">Rate</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {services[31].map(
                                                  (miscrate, idx) => (
                                                    <tr key={`mis${idx}`}>
                                                      <td>
                                                        {miscrate.misc_title}
                                                      </td>
                                                      <td>
                                                        {miscrate.misc_type ===
                                                        "per_minute"
                                                          ? "Per Minute"
                                                          : ""}
                                                        {miscrate.misc_type ===
                                                        "per_hour"
                                                          ? "Per Hour"
                                                          : ""}
                                                        {miscrate.misc_type ===
                                                        "one_time"
                                                          ? "One Time"
                                                          : ""}
                                                      </td>
                                                      <td>
                                                        {miscrate.misc_rate !==
                                                          "" &&
                                                        miscrate.misc_rate >
                                                          0 ? (
                                                          <>
                                                            $
                                                            {miscrate.misc_rate.toFixed(
                                                              2
                                                            )}
                                                          </>
                                                        ) : (
                                                          "$0.00"
                                                        )}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                    <div className="card-inner">
                                      <div className="sp-plan-head">
                                        <h6 className="title">
                                          Add / Update Dates
                                        </h6>
                                      </div>
                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                        <ul className="row gx-1">
                                          <li className="col-sm-5">
                                            <p>
                                              <span className="text-soft">
                                                Date Added
                                              </span>{" "}
                                              {services[19] !== ""
                                                ? this.format_date(services[19])
                                                : null}
                                            </p>
                                          </li>
                                          <li className="col-sm-5">
                                            <p>
                                              <span className="text-soft">
                                                Date Updated
                                              </span>{" "}
                                              {services[20] !== ""
                                                ? this.format_date(services[20])
                                                : null}
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    {services[35] && services[35] === "link" && services[36] !== "" ? (
                                      <>
                                        {services[36] !== "" ? (
                                          <div className="card-inner">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Marketing Link
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Marketing Link
                                                    </span>{" "}
                                                    <a
                                                      href={services[36]}
                                                      target="_blank"
                                                    >
                                                      <em className="icon ni ni-link-h"></em>{" "}
                                                      Click to open link
                                                    </a>
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                    {services[35] && services[35] === "pdf" && services[36] !== "" ? (
                                      <>
                                        {services[36] !== "" ? (
                                          <div className="card-inner">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Marketing Link
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Marketing Link
                                                    </span>{" "}
                                                    <a
                                                      href={services[36]}
                                                      target="_blank"
                                                    >
                                                      <em className="icon ni ni-file-pdf"></em>{" "}
                                                      View PDF
                                                    </a>
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ) : null}
                                      </>
                                    ) : null}
                                    <div className="card-inner">
                                      <div className="sp-plan-head-group">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                            Short Description
                                          </h6>
                                          <span className="ff-italic text-soft">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: services[3],
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* DETAILS MODAL */}
                {/* ALERT KYC MODAL */}
                <div
                  class="modal fade"
                  tabindex="-1"
                  id="modalAlertCompleteKyc"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-body modal-body-lg text-center">
                        <div class="nk-modal">
                          <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-user-list bg-danger"></em>
                          <h4 class="nk-modal-title">KYC Incomplete!</h4>
                          <div class="nk-modal-text">
                            <p class="lead">
                              Your KYC profile is currently incomplete. To
                              unlock additional services, kindly click the
                              "Complete Profile" button below to finalize and
                              update your KYC information.
                            </p>
                          </div>
                          <div class="nk-modal-action mt-5">
                            <a
                              href={this.state.kyc_link}
                              class="btn btn-lg btn-block btn-mw btn-primary"
                              target="_blank"
                            >
                              Complete Profile
                            </a>
                            <a
                              href="#"
                              class="btn btn-lg btn-block  btn-mw btn-light"
                              data-dismiss="modal"
                            >
                              Close
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ALERT KYC MODAL */}
                {/* MODAL ORDER SERVICE */}
                <div class="modal fade" tabindex="-1" id="modalOrderService">
                  <div
                    class="modal-dialog modal-xl modal-dialog-top"
                    role="document"
                  >
                    <div class="modal-content">
                      <a
                        href="#"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em class="icon ni ni-cross"></em>
                      </a>
                      <div class="modal-header">
                        <h5 class="modal-title">
                          Order New Service{" "}
                          <sup>({this.state.service_name})</sup>
                        </h5>
                      </div>
                      <div class="modal-body">
                        {this.state.capture_projection !== "" &&
                        this.state.capture_projection === "Yes" ? (
                          <div class="nk-block-between-md g-4">
                            <div class="nk-block-head-content">
                              <h4 class="nk-block-title fw-normal">
                                Forecasting
                              </h4>
                              <div class="nk-block-des">
                                <p>Forecasting {this.state.service_name} </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div class="nk-block-between-md g-4">
                            <div class="nk-block-head-content">
                              <h4 class="nk-block-title fw-normal">
                                Continue Activation
                              </h4>
                              <div class="nk-block-des">
                                <p>
                                  {" "}
                                  Activate {this.state.service_name}. Click
                                  'Continue' to enable this service.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.errorMessageForcast !== "" ? (
                          <div className="example-alert mb-3 mt-3">
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessageForcast}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessageForcast !== "" ? (
                          <div className="example-alert mb-3 mt-3">
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessageForcast}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.capture_projection !== "" &&
                        this.state.capture_projection === "Yes" ? (
                          <form
                            method="post"
                            encType="multipart/form-data"
                            className="mt-4"
                          >
                            <div className="row g-4">
                              <div className="form-group col-md-6">
                                <MutextField
                                  required
                                  id="monthly_usage"
                                  name="monthly_usage"
                                  type="text"
                                  label="Est. Monthly Usage"
                                  inputProps={{ maxLength: 8 }}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/gi,
                                      ""
                                    );
                                  }}
                                  defaultValue={this.state.monthly_usage}
                                  onChange={this.handleChange}
                                  helperText=""
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <MutextField
                                  required
                                  id="numbers_quantity"
                                  name="numbers_quantity"
                                  type="text"
                                  inputProps={{ maxLength: 4 }}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/gi,
                                      ""
                                    );
                                  }}
                                  label="Qty of Numbers / Optional"
                                  defaultValue={this.state.numbers_quantity}
                                  onChange={this.handleChange}
                                  helperText=""
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <MutextField
                                  required
                                  id="usage_type"
                                  name="usage_type"
                                  type="text"
                                  label="Description of Usage Type"
                                  defaultValue={this.state.usage_type}
                                  onChange={this.handleChange}
                                  helperText=""
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <MutextField
                                  required
                                  id="estimated_sessions"
                                  name="estimated_sessions"
                                  type="number"
                                  label="Est. Sessions"
                                  inputProps={{ maxLength: 11 }}
                                  defaultValue={this.state.estimated_sessions}
                                  onChange={this.handleChange}
                                  helperText=""
                                  variant="outlined"
                                  aria-readonly
                                  fullWidth
                                />
                              </div>

                              <div style={{ clear: "both" }}></div>
                              <hr />
                              <div className="form-group text-right col-md-12">
                                {this.state.submitButton === true ? (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      class="spinner-grow spinner-grow-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span> Loading... </span>
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    type="button"
                                    disabled={this.state.disabled}
                                    onClick={this.formSaveWithProjection}
                                  >
                                    <span>Continue</span>
                                    <em class="icon ni ni-arrow-right-round"></em>
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        ) : (
                          <div className="row g-4">
                            <div className="form-group text-right col-md-12">
                              {this.state.submitButton === true ? (
                                <button
                                  className="btn btn-lg btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    class="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Loading... </span>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-lg btn-primary"
                                  type="button"
                                  disabled={this.state.disabled}
                                  onClick={this.formSaveWithoutProjection}
                                >
                                  <span>Continue</span>
                                  <em class="icon ni ni-arrow-right-round"></em>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* MODAL ORDER SERVICE */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
