import { React } from "react";
import { Component } from "react";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import "../../../components/Client/signatureCanvas.css";
import SignatureCanvas from "react-signature-canvas";
import { addSettings } from "../../../actions";
import {
  ValidateEmail,
  axiosConfig,
  normalizePhone,
  ValidatePasswordPattern,
  formatAsCurrency,
} from "../../../config/utility";
import {
  getSingleQuotation,
  listSingleClient,
  getAssignedQuotationSettings,
  saveQuotationComment,
  listServicesWithPrice,
  saveChooseQuotationSignature,
  saveQuotationSignature,
  saveSalesOrder,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import Questions from "../../../components/Client/RequestForQuotation/questions";
import ExtraDetails from "../../../components/Client/RequestForQuotation/extra";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { HeadBodyGrid } from "../../../components/ContentLoaders/newLoaders/HeadBodyGrid";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import { Editor } from "@tinymce/tinymce-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import htmlToFormattedText from "html-to-formatted-text";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import html2canvas from "html2canvas";

const animatedComponents = makeAnimated();

const auth = new HelperClass();

class ViewOrder extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      tableDataBundle: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      successMessagePay: "",
      errorMessagePay: "",
      errorMessagePayTitle: "",
      tableLoader: true,
      tableLoaderListing: true,
      mcqLoader: true,
      tableLoaderBundle: true,
      errorMessageEmail: "",
      VerifyBusinessButton: false,
      VerifyFinancialButton: false,
      VerifyTradeButton: false,
      verifyDetailsButton: false,
      resendPinButton: false,
      finalPayButton: false,
      successMessageEmail: "",
      errorMessagePassword: "",
      errorMessageConsent: "",
      errorMessageFinal: "",
      convErrorMessage: "",
      service_success_message: "",
      service_already_eists_message: "",
      payment_method: "bank",
      bank_account_details: "",
      wire_transfer_details: "",
      bank_account_details_plain: "",
      wire_transfer_details_plain: "",
      final_form_step: "signature",
      step: 1,
      email: "",
      copied: false,
      cpied_value: "",
      show_signature: false,
      disabled: false,
      //Client Details
      client_name: "",
      client_company: "",
      client_email: "",
      client_phone: "",
      client_address: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      //Reseller Details
      reseller_name: "",
      reseller_company: "",
      reseller_email: "",
      reseller_phone: "",
      reseller_address: "",
      reseller_city: "",
      reseller_state: "",
      reseller_zip: "",
      //Discount Entries
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
      discount_reason: "",
      //Shipping Entries
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
      quotation_signature: "",
      //Tax Details
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
      tax_reason: "",
      //Quotation Settings
      quotation_notes: "",
      payment_terms: "",
      currency: "USD",
      contact_person: "",
      contact_email: "",
      validity: "",
      errorMessageInfo: "",
      successMessageInfo: "",
      info_button: false,
      conversations: [],
      quote_accountno: "",
      orderno: "",
      quote_comment_button: false,
      quote_comment: "",

      service_grand_total: 0,
      service_grand_sub_total: 0,
      rows: [],
      all_Services: [],
      all_select_services: [],
      all_select_services_optional: [],

      signatureText: "",
      signatureClass: "'Amita', cursive",
      signature_type: "draw",

      quote_status: "",

      rows_service_temp: [
        {
          temp_serv_id: "row-135",
          temp_serv_data: {
            temp_serv_inner_id: 940,
            temp_serv_inner_name: "Item 1",
            temp_serv_inner_quantity: 2,
            temp_serv_inner_unit: "sbc",
            temp_serv_inner_price: 10,
            temp_serv_inner_total: 10,
            temp_serv_inner_type: "service",
          },
        },
      ],
      rows_optional: [],
      rows_optional_temp: [
        {
          temp_opt_id: "row-145",
          temp_opt_data: {
            temp_opt_inner_id: 940,
            temp_opt_inner_name: "Item 1",
            temp_opt_inner_quantity: 2,
            temp_opt_inner_unit: "sbc",
            temp_opt_inner_price: 10,
            temp_opt_inner_total: 10,
            temp_opt_inner_type: "service",
          },
        },
      ],
      rows_custom_temp: [
        {
          temp_id: "row-125",
          temp_data: {
            temp_inner_id: 94,
            temp_inner_name: "Item 1",
            temp_inner_quantity: 2,
            temp_inner_unit: "sbc",
            temp_inner_price: 10,
            temp_inner_total: 10,
            temp_inner_type: "custom",
          },
        },
      ],
    };
    this.cursiveFonts = [
      { name: "Your Signature", value: "'Amita', cursive" },
      { name: "Your Signature", value: "'Bad Script', cursive" },
      { name: "Your Signature", value: "'Damion', cursive" },
      { name: "Your Signature", value: "'Dancing Script', cursive" },
      { name: "Your Signature", value: "'Felipa', cursive" },
      { name: "Your Signature", value: "'Julee', cursive" },
      { name: "Your Signature", value: "'Kaushan Script', cursive" },
      { name: "Your Signature", value: "'Lugrasimo', cursive" },
      { name: "Your Signature", value: "'Pacifico', cursive" },
      { name: "Your Signature", value: "'Sofia', cursive" },
      { name: "Your Signature", value: "'Yellowtail', cursive" },
      // Add more cursive fonts here
    ];
  }

  async componentDidMount() {
    let quote_accountno = this.props.props.match.params.orderno;
    this.setState({ quote_accountno: quote_accountno });
    console.log("quote_accountno: ", quote_accountno);
    if (
      this.props.props.match.params.quote_accountno &&
      this.props.props.match.params.quote_accountno !== ""
    ) {
      //this.getSingleQuotation(quote_accountno);
      //this.getQuotationSettings(quote_accountno);
    }

    //this.getSingleClient();
    //this.getServices();
    /*let signaturePad;
    $('#modalSignAndPay').on('shown.bs.modal',function(e){
        let canvas = $("#signature-pad canvas");
        let parentWidth = $(canvas).parent().outerWidth();
        let parentHeight = $(canvas).parent().outerHeight();
        canvas.attr("width", parentWidth+'px')
              .attr("height", parentHeight+'px');
              this.signaturePad = new SignaturePad(canvas[0], {
            backgroundColor: 'rgb(255, 255, 255)'
        });
    })*/
  }

  getServices = async () => {
    const servicesResponce = await listServicesWithPrice(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("getServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_select_services = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label: servicesResponce.data.data[i][1],
            value: servicesResponce.data.data[i][0],
          };
          all_select_services.push(newArr);
        }
      }
      this.setState({
        all_select_services: all_select_services,
        all_select_services_optional: all_select_services,
        all_services: servicesResponce.data.data,
      });
    }
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: ", typeof amount);
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");

    return (
      <div className="nk-content rfq_Block" id="editQuotations">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" + auth.getResellerRegToken() + "/client/"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" +
                              auth.getResellerRegToken() +
                              "/client/SalesOrders"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Sales Orders
                      </Link>

                      <Typography color="textPrimary" className="">
                        Order Details
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-aside-wrap">
                    <div class="card-content">
                      <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#tabOrderSummary"
                          >
                            <em class="icon ni ni-user-circle"></em>
                            <span>Order Summary</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getPaymentMethod()}
                            href="#tabPaymentMethod"
                          >
                            <em class="icon ni ni-repeat"></em>
                            <span>Payments</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getPaymentLogs()}
                            href="#tabPaymentLogs"
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Payment Logs</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getOrderComments()}
                            href="#tabOrderComments"
                          >
                            <em class="icon ni ni-bell"></em>
                            <span>Comments</span>
                          </a>
                        </li>
                        {/*<li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabOrderQuotation"
                          >
                            <em class="icon ni ni-activity"></em>
                            <span>Quotation</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabOrderRfq"
                          >
                            <em class="icon ni ni-activity"></em>
                            <span>RFQ</span>
                          </a>
                      </li>*/}
                      </ul>
                      <div class="tab-content">
                        <div class="tab-pane active" id="tabOrderSummary">
                          <div
                            class="invoice-wrap"
                            style={{ border: "0px", padding: "2rem" }}
                          >
                            <div class="invoice-head">
                              <div class="invoice-contact">
                                <span class="overline-title">Invoice To</span>
                                <div class="invoice-contact-info">
                                  <h4 class="title">
                                    {this.state.client_company}
                                  </h4>
                                  <ul class="list-plain">
                                    {this.state.client_address &&
                                    this.state.client_address !== "" ? (
                                      <li>
                                        <em className="icon ni ni-map-pin-fill"></em>
                                        <span className="text-dark">
                                          {this.state.client_address &&
                                          this.state.client_address !== ""
                                            ? this.state.client_address
                                            : ""}
                                          <br />
                                          {this.state.client_city &&
                                          this.state.client_city !== ""
                                            ? this.state.client_city
                                            : ""}{" "}
                                          {this.state.client_state &&
                                          this.state.client_state !== ""
                                            ? this.state.client_state
                                            : ""}{" "}
                                          {this.state.client_zip &&
                                          this.state.client_zip !== ""
                                            ? this.state.client_zip
                                            : ""}
                                        </span>
                                      </li>
                                    ) : null}
                                    <li>
                                      <em class="icon ni ni-call-fill"></em>
                                      <span className="text-dark">
                                        {this.state.client_phone &&
                                        this.state.client_phone !== ""
                                          ? auth.formatUsPhoneNumber(
                                              this.state.client_phone
                                            )
                                          : "---"}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="invoice-desc right_invoice">
                                <ul class="list-plain">
                                  <li class="invoice-date">
                                    <span>Date</span>:
                                    <span>
                                      {this.state.order_date &&
                                      this.state.order_date !== ""
                                        ? this.format_date(
                                            this.state.order_date
                                          )
                                        : ""}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="invoice-bills">
                              <div class="table-responsive">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>Item ID</th>
                                      <th>Description</th>
                                      <th>Qty</th>
                                      <th>Unit</th>
                                      <th>Price</th>
                                      <th>Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.rows.map((row, index) => (
                                      <>
                                        {row.data.type &&
                                        row.data.type === "service" ? (
                                          <tr key={`serv-${index}`}>
                                            <td>
                                              {row.data.id && row.data.id !== ""
                                                ? row.data.id
                                                : "---"}
                                            </td>
                                            <td>
                                              {row.data.name &&
                                              row.data.name !== ""
                                                ? row.data.name
                                                : "---"}
                                            </td>
                                            <td>{row.data.quantity}</td>
                                            <td>{row.data.unit}</td>
                                            <td>${row.data.price}</td>
                                            <td>
                                              {this.formatAsCurrency(
                                                parseFloat(row.data.total)
                                              )}
                                            </td>
                                          </tr>
                                        ) : row.data.type === "section" ? (
                                          <tr key={`serv-${index}`}>
                                            <td
                                              className="text-left"
                                              style={{ fontWeight: "bold" }}
                                              colSpan={6}
                                            >
                                              {row.data.name}
                                            </td>
                                          </tr>
                                        ) : row.data.type === "custom" ? (
                                          <tr key={`serv-${index}`}>
                                            <td>{row.data.id}</td>
                                            <td>
                                              {row.data.name &&
                                              row.data.name !== ""
                                                ? row.data.name
                                                : "---"}
                                            </td>
                                            <td>{row.data.quantity}</td>
                                            <td>{row.data.unit}</td>
                                            <td>${row.data.price}</td>
                                            <td>
                                              {this.formatAsCurrency(
                                                parseFloat(row.data.total)
                                              )}
                                            </td>
                                          </tr>
                                        ) : null}
                                      </>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="3"></td>
                                      <td colspan="2">Subtotal</td>
                                      <td>
                                        {this.state.service_grand_sub_total}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="3"></td>
                                      <td colspan="2">Est. Discount</td>
                                      <td>
                                        {this.formatAsCurrency(
                                          parseFloat(
                                            this.state.discount_value_total
                                          )
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="3"></td>
                                      <td colspan="2">Est. Shipping</td>
                                      <td>
                                        {this.formatAsCurrency(
                                          parseFloat(
                                            this.state.shipping_value_total
                                          )
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="3"></td>
                                      <td colspan="2">Est. TAX</td>
                                      <td>
                                        {this.formatAsCurrency(
                                          parseFloat(this.state.tax_value_total)
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="3"></td>
                                      <td colspan="2">Grand Total</td>
                                      <td>{this.state.service_grand_total}</td>
                                    </tr>
                                  </tfoot>
                                </table>

                                <div class="card card-bordered mt-5">
                                  <div class="card-inner">
                                    <div class="nk-help">
                                      <div class="nk-help-text">
                                        <h5>Notes</h5>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.quote_notes,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="tabPaymentMethod">
                          <div className="col-md-12">
                            {this.state.payErrorMessage !== "" ? (
                              <div class="alert alert-pro alert-danger">
                                <div class="alert-text">
                                  <h6>Error</h6>
                                  <p>{this.state.payErrorMessage}</p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.paySuccessMessage !== "" ? (
                              <div class="alert alert-pro alert-success">
                                <div class="alert-text">
                                  <h6>Success</h6>
                                  <p>{this.state.paySuccessMessage}</p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.paymentMethodLoader === true ? (
                              FormLoader()
                            ) : (
                              <div class="card card-bordered mt-2">
                                <div class="card-inner">
                                  <div class="nk-help">
                                    <div class="nk-help-text">
                                      {this.state.payment_status &&
                                      this.state.payment_status === "unpaid" ? (
                                        <div className="col-md-12 prettyprint2 mb-4">
                                          <h5>Mark as paid </h5>
                                          <p>
                                            Processed by{" "}
                                            {this.state.payment_method ===
                                            "bank"
                                              ? "Bank Account"
                                              : this.state.payment_method ===
                                                "wire"
                                              ? "Wire Transfer"
                                              : ""}
                                          </p>
                                          <p>
                                            If you received payment manually,
                                            mark this order as paid. This
                                            payment won't be captured by the
                                            Syatem and you wont be able to
                                            refund it using the syatem. You can
                                            also add notes of the payment.
                                          </p>

                                          <div class="form-group">
                                            <label
                                              class="form-label"
                                              for="payment_notes"
                                            >
                                              Payment Notes
                                            </label>
                                            <div class="form-control-wrap">
                                              <input
                                                type="text"
                                                class="form-control form-control-lg"
                                                id="payment_notes"
                                                name="payment_notes"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.payment_notes
                                                }
                                                placeholder="Add payment notes with a maximum of 100 characters.."
                                                maxLength={100}
                                                autoComplete="no"
                                              />
                                            </div>
                                          </div>

                                          {/*<div class="form-group">
                                            <label
                                              class="form-label"
                                              for="payment_receipt"
                                            >
                                              Receipt
                                            </label>
                                            <div class="form-control-wrap">
                                              <div class="custom-file">
                                                <input
                                                  type="file"
                                                  id="payment_receipt"
                                                  name="payment_receipt"
                                                  defaultValue={this.state.payment_receipt}
                                                  class="custom-file-input form-control-lg"
                                                  
                                                  accept=".png, .jpg, .jpeg, .pdf"
                                                  onChange={this.handleFileChange}
                                                />
                                                <label
                                                  class="custom-file-label"
                                                  for="customFile"
                                                >
                                                  Choose receipt (.png, .jpg, .jpeg, .pdf)
                                                </label>
                                              </div>
                                            </div>
                                            </div>*/}
                                          <div className="row g-gs  d-flex justify-content-end">
                                            <div class="nk-block-head-content">
                                              <ul class="nk-block-tools gx-3">
                                                {this.state.payment_button ===
                                                true ? (
                                                  <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    disabled
                                                  >
                                                    <span
                                                      className="spinner-grow spinner-grow-sm"
                                                      role="status"
                                                      aria-hidden="true"
                                                    ></span>
                                                    <span> Processing... </span>
                                                  </button>
                                                ) : (
                                                  <li>
                                                    <a
                                                      onClick={() =>
                                                        this.markAsPaid()
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                      }}
                                                      class="btn btn-success"
                                                    >
                                                      <em class="icon ni ni-check-round-cut"></em>{" "}
                                                      <span>Mark as paid</span>{" "}
                                                    </a>
                                                  </li>
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      <h5>Payment Method</h5>
                                      <p className="prettyprint2 mt-2 mb-4">
                                        {this.state.payment_method === "bank"
                                          ? "Bank Account"
                                          : this.state.payment_method === "wire"
                                          ? "Wire Transfer"
                                          : ""}
                                      </p>
                                      <h5 className="mt-4">
                                        {this.state.payment_method === "bank"
                                          ? "Bank Account Details"
                                          : this.state.payment_method === "wire"
                                          ? "Wire Transfer Details"
                                          : ""}
                                      </h5>
                                      <p className="prettyprint2 mt-2">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.payment_details,
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="tab-pane" id="tabPaymentLogs">
                          <div className="col-md-12">
                            {this.state.paymentLogLoader === true ? (
                              FormLoader()
                            ) : (
                              <div class="card card-bordered mt-2">
                                <div class="card-inner">
                                  <div class="nk-help">
                                    <div class="nk-help-text">
                                      {this.state.payment_logs &&
                                      this.state.payment_logs.length > 0 ? (
                                        <>
                                          <h5>Payment Logs</h5>
                                          <div class="table-responsive">
                                            <table class="table table-striped">
                                              <thead>
                                                <tr>
                                                  <th>Order #</th>
                                                  <th>Method</th>
                                                  <th>Date</th>
                                                  <th>Amount</th>
                                                  <th>Receipt</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.payment_logs.map(
                                                  (logs, index) => (
                                                    <tr key={`log-${index}`}>
                                                      <td>{logs.orderno}</td>
                                                      <td>
                                                        {logs.payment_method ===
                                                        "bank"
                                                          ? "Bank Account"
                                                          : logs.payment_method ===
                                                            "wire"
                                                          ? "Wire Transfer"
                                                          : ""}
                                                      </td>
                                                      <td>
                                                        {this.format_date(
                                                          logs.dateCreated
                                                        )}
                                                      </td>
                                                      <td>
                                                        {" "}
                                                        {this.formatAsCurrency(
                                                          parseFloat(
                                                            logs.amount
                                                          )
                                                        )}
                                                      </td>
                                                      <td>
                                                        {logs.payment_receipt &&
                                                        logs.payment_receipt !==
                                                          ""
                                                          ? logs.payment_receipt
                                                          : "---"}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </>
                                      ) : (
                                        <div class="alert alert-pro alert-warning">
                                          <div class="alert-text">
                                            <h6>Not Found</h6>
                                            <p>Payment logs not found. </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="tab-pane" id="tabOrderComments">
                          <div className="col-md-12">
                            {this.state.orderCommentLoader === true ? (
                              FormLoader()
                            ) : (
                              <div class="card card-bordered mt-2">
                                <div class="card-inner">
                                  <div class="nk-help">
                                    <div class="nk-help-text">
                                      {this.state.order_comments &&
                                      this.state.order_comments.length > 0 ? (
                                        <>
                                          {this.state.order_comments.map(
                                            (quote, index) => (
                                              <div
                                                className="nk-reply-item"
                                                key={`keys-${index}`}
                                              >
                                                <div className="nk-reply-header">
                                                  <div className="user-card">
                                                    {quote.from_admin !== "" ? (
                                                      <div className="user-avatar sm bg-blue">
                                                        <span>AD</span>
                                                      </div>
                                                    ) : quote.from_client !==
                                                      "" ? (
                                                      <div className="user-avatar sm bg-pink">
                                                        <span>CL</span>
                                                      </div>
                                                    ) : null}

                                                    <div className="user-name">
                                                      {quote.from_admin !== ""
                                                        ? quote.from_admin
                                                        : quote.from_client !==
                                                          ""
                                                        ? quote.from_client
                                                        : null}
                                                    </div>
                                                  </div>
                                                  <div className="date-time">
                                                    {this.format_date(
                                                      quote.dateAdded
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="nk-reply-body">
                                                  <div className="nk-reply-entry entry">
                                                    <p>Hello,</p>
                                                    <p>{quote.conversation}</p>
                                                    {quote.from_admin !== "" ? (
                                                      <p>
                                                        Thank you <br />{" "}
                                                        {quote.from_admin}
                                                      </p>
                                                    ) : null}
                                                    {quote.from_client !==
                                                    "" ? (
                                                      <p>
                                                        Thank you <br />{" "}
                                                        {quote.from_client}
                                                      </p>
                                                    ) : null}
                                                  </div>
                                                  {quote.quote_file !== "" ? (
                                                    <div className="attach-files invoicefiles">
                                                      <ul className="attach-list">
                                                        <li className="attach-item">
                                                          <a
                                                            className="download"
                                                            href="#"
                                                          >
                                                            <em className="icon ni ni-img"></em>
                                                            <span>
                                                              {quote.quote_file}
                                                            </span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                      <div className="attach-foot">
                                                        <span className="attach-info">
                                                          1 file attached
                                                        </span>
                                                        {this.state
                                                          .download_start ===
                                                        true ? (
                                                          <div
                                                            class="spinner-border spinner-border-sm"
                                                            role="status"
                                                          >
                                                            <span class="sr-only">
                                                              Loading...
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <a
                                                            className="attach-download link"
                                                            onClick={() =>
                                                              this.downloadPdf(
                                                                quote.quote_file
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            <em className="icon ni ni-download"></em>
                                                            <span>
                                                              Download
                                                            </span>
                                                          </a>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <div class="alert alert-pro alert-warning">
                                          <div class="alert-text">
                                            <h6>Not Found</h6>
                                            <p>Order comments not found. </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="tab-pane" id="tabOrderQuotation">
                          <div className="col-md-12">
                            <div class="card card-bordered mt-2">
                              <div class="card-inner">
                                <div class="nk-help">
                                  <div class="nk-help-text">
                                    <h5>Order Quotations</h5>
                                    <p>Order Quotations</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="tabOrderRfq">
                          <div className="col-md-12">
                            <div class="card card-bordered mt-2">
                              <div class="card-inner">
                                <div class="nk-help">
                                  <div class="nk-help-text">
                                    <h5>Order RFQ</h5>
                                    <p>Order RFQ</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div class="card-inner-group" data-simplebar>
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            <div class="user-info">
                              <div class="badge badge-lg badge-outline-primary badge-pill ucap fw-bold">
                                Order #: {this.state.orderno}
                              </div>
                              <h5>{this.state.client_name}</h5>
                              <span class="sub-text">
                                {this.state.client_email}
                              </span>
                              
                              {this.state.quote_status &&
                              this.state.quote_status === "cancelled" ? (
                                <div class="badge badge-lg badge-outline-danger badge-pill ucap fw-bold mt-2">
                                  Order Status: {this.state.quote_status}
                                </div>
                              ) : this.state.quote_status &&
                                this.state.quote_status === "completed" ? (
                                <div class="badge badge-lg badge-outline-success badge-pill ucap fw-bold mt-2">
                                  Order Status: {this.state.quote_status}
                                </div>
                              ) : null}
                              {this.state.payment_status &&
                              this.state.payment_status === "unpaid" ? (
                                <div class="badge badge-lg badge-outline-danger badge-pill ucap fw-bold mt-2">
                                  Payment Status: {this.state.payment_status}
                                </div>
                              ) : this.state.payment_status &&
                                this.state.payment_status === "paid" ? (
                                <div class="badge badge-lg badge-outline-success badge-pill ucap fw-bold mt-2">
                                  Payment Status: {this.state.payment_status}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div class="card-inner card-inner-sm">
                          <ul class="btn-toolbar justify-center gx-1">
                            <li className="mr-2">
                              <Tooltip title="Email" placement="top">
                                <a
                                  onClick={() => this.openEmailWindow()}
                                  class="btn btn-trigger btn-icon"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em class="icon ni ni-mail fs-20px"></em>
                                </a>
                              </Tooltip>
                            </li>
                            <li className="mr-2">
                              <Tooltip
                                title="Download Sales Order PDF"
                                placement="top"
                              >
                                <a
                                  onClick={() =>
                                    this.downloadOrderPdf(
                                      this.state.quote_attachement
                                    )
                                  }
                                  class="btn btn-trigger btn-icon"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em class="icon ni ni-download-cloud fs-20px"></em>
                                </a>
                              </Tooltip>
                            </li>
                            {this.state.quote_status === "completed" &&
                            this.state.payment_status === "unpaid" ? (
                              <li>
                                <Tooltip title="Cancel Order" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.rejectSalesOrder()}
                                    class="btn btn-trigger btn-icon text-danger"
                                  >
                                    <em class="icon ni ni-na fs-20px text-danger"></em>
                                  </a>
                                </Tooltip>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                        <div class="card-inner">
                          <div class="overline-title-alt mb-2">Grand Total</div>
                          <div class="profile-balance">
                            <div class="profile-balance-group gx-4">
                              <div class="profile-balance-sub">
                                <div class="profile-balance-amount">
                                  <div class="number">
                                    {this.state.service_grand_total}{" "}
                                  </div>
                                </div>
                                <div class="profile-balance-subtitle">
                                  Order Amount
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-inner">
                          <h6 class="overline-title-alt mb-2">
                            Recurring Billing
                          </h6>
                          <div class="row text-center">
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">0</span>
                                <span class="sub-text"> Invoices</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">$0.00</span>
                                <span class="sub-text"> Payments</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">$0.00</span>
                                <span class="sub-text"> Commissions</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-inner">
                          <h6 class="overline-title-alt mb-2">Additional</h6>
                          <div class="row g-3">
                            <div class="col-6">
                              <span class="sub-text">From:</span>
                              <span>{this.state.reseller_company}</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Payment terms:</span>
                              <span>{this.state.payment_terms}</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Currency:</span>
                              <span>{this.state.currency}</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Contact Person:</span>
                              <span>{this.state.contact_person}</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Validity:</span>
                              <span>{this.state.validity} Days</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Quotation:</span>
                              <a
                                onClick={() =>
                                  this.addMenuHistory(
                                    "/EditQuotations/" +
                                      this.state.client_accountno +
                                      "/" +
                                      this.state.quote_accountno
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <span>
                                  <span class="badge badge-outline-primary">
                                    <em class="icon ni ni-card-view"></em> View
                                  </span>
                                </span>
                              </a>
                            </div>
                            {this.state.rfq_accountno &&
                            this.state.rfq_accountno !== "" ? (
                              <div class="col-6">
                                <span class="sub-text">RFQ:</span>
                                <a onClick={() => this.showRequirements()} style={{ cursor: "pointer" }}>
                                <span>
                                  <span class="badge badge-outline-primary">
                                    <em class="icon ni ni-card-view"></em> View
                                  </span>
                                </span>
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder);
