import { React, Component } from "react";

import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ReCAPTCHA from "react-google-recaptcha";

import {
  registerClient,
  checkAgentAccount,
  resendClientVerificationEmail,
} from "../../../config/client_api_calls";

import $ from "jquery";
import { checkAgent } from "./../../../config/api_calls";
import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";
import { ValidateEmail, axiosConfig, normalize } from "../../../config/utility";

import { PhoneInput } from "react-international-phone";

//import { resendCodeFun } from "../../../config/api_calls";

import TextField from "./TextField";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/FormLoader";
import HelperClass from "./../../../config/helperClass";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

import Step1 from "../../../components/Client/ClientRegister/Step1";
import Step2 from "../../../components/Client/ClientRegister/Step2";
import Step3 from "../../../components/Client/ClientRegister/Step3";
import Step4 from "../../../components/Client/ClientRegister/Step4";
import Step5 from "../../../components/Client/ClientRegister/Step5";
import Step6 from "../../../components/Client/ClientRegister/Step6";
import Select from 'react-select'
import countryList from 'react-select-country-list'

const auth = new HelperClass();

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      company_name: "",
      title: "",
      email: "",
      password: "",
      errorEmail: "",
      errorPass: "",
      errorcPass: "",
      errorName: "",
      errorMessage: "",
      errorCompName: "",
      errorTitle: "",
      disabled: false,
      successMessage: "",
      confirm_password: "",
      errorConfirm: "",
      disableResendBtn: false,
      gifLoader: false,
      phone: "",
      errorPhone: "",
      fieldsHide: false,
      show_form: false,
      resellerData: [],
      tableLoader: true,
      res_accountno: "",
      res_title: "",
      super_agent_accountno: "",
      agent_title: "",
      action_type: "",
      pageError: "",
      res_url: "",
      url_type: "Simple",
      step: 1,
      filer_id: "",
      select_499: false,
      no_of_employes: "",
      service_id: "",
      service_type: "",
      packagesData: [],
      servicesData: [],
      portingData: [],
      miscData: [],
      subscriptio_type: "",
      passwordShown: false,
      confirmPasswordShown: false,
      address: "",
      address2: "",
      country: null,
      city: "",
      state: "",
      zip: "",
      clientRegisteredEmail: "",
      clientRegisteredAccountno: "",
      afterRegistration: false,
    };
    this.site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    this.captcha_token = null;
    this.countryOptions = countryList().getData();
  }

  async componentDidMount() {
    let signup_hash = this.props.match.params.signup_hash;
    let service_id = this.props.match.params.serviceid;
    let servicetype = this.props.match.params.servicetype;
    /*if (servicetype === "package") {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        pageError: "Package based registration is coming soon.",
      });
    } else {*/
    if (signup_hash !== "") {
      //Errors
      //404 and no_reseller
      //404 and no_agent
      let action_type = "client";
      let urlElements = window.location.href.split("/");
      console.log("CUrrent URL: ", urlElements[0] + "//" + urlElements[2]);
      let host_url = urlElements[2];
      if (service_id && service_id !== "") {
        this.setState({
          service_id: service_id,
        });
      }
      if (!servicetype && service_id === "") {
        servicetype = "";
      }
      try {
        let response = await checkAgentAccount({
          signup_hash: signup_hash,
          action_type: action_type,
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          host_url: host_url,
          service_id: service_id,
          servicetype: servicetype,
        });
        console.log("Agent.response.data : ", response.data);
        if (
          response.data.status === 404 ||
          response.data.status === 422 ||
          response.data.errors !== ""
        ) {
          this.setState({
            errorMessage: "There is some error.",
            successMessage: "",
            show_form: false,
            pageError: "",
          });
        }

        if (
          response.data.status === 404 &&
          response.data.message === "no_reseller"
        ) {
          this.setState({
            errorMessage: "",
            pageError:
              "Agent does not exists. Please contact support or try again with correct URL.",
            successMessage: "",
            show_form: false,
          });
        } else if (
          response.data.status === 404 &&
          response.data.message === "no_agent"
        ) {
          this.setState({
            pageError:
              "Agent does not exists. Please contact support or try again with correct URL.",
            errorMessage: "",
            successMessage: "",
            show_form: false,
          });
        } else if (response.data.status === 403) {
          this.setState({
            errorMessage: "There is some error.",
            successMessage: "",
            show_form: false,
            pageError: "",
          });
        } else if (
          response.data.status === 200 &&
          response.data.message === "success"
        ) {
          Cookies.set(
            "restkn__",
            CryptoJS.AES.encrypt(
              signup_hash,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "resacct__",
            CryptoJS.AES.encrypt(
              response.data.res_accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "resregtype__",
            CryptoJS.AES.encrypt(
              response.data.action_type,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "restitle__",
            CryptoJS.AES.encrypt(
              response.data.reseller_title,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "agttitle__",
            CryptoJS.AES.encrypt(
              response.data.agent_title,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "agtacctno__",
            CryptoJS.AES.encrypt(
              response.data.agent_accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "reslogo__",
            CryptoJS.AES.encrypt(
              response.data.reseller_logo,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "ressig__",
            CryptoJS.AES.encrypt(
              response.data.reseller_signature,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "residentifier__",
            CryptoJS.AES.encrypt(
              response.data.reseller_domain_identifier,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "urltype__",
            CryptoJS.AES.encrypt(
              response.data.url_type,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "clientserviceid__",
            CryptoJS.AES.encrypt(
              service_id,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "clientservicetype__",
            CryptoJS.AES.encrypt(
              servicetype,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          if (servicetype === "service") {
            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              show_form: true,
              agent_title: response.data.agent_title,
              super_agent_accountno: response.data.agent_accountno,
              action_type: action_type,
              pageError: "",
              servicesData: response.data.servicesListing,
              service_type: response.data.servicesListing[0].service_type,
              portingData: response.data.portingListing,
              miscData: response.data.miscListing,
              packagesData: [],
              subscriptio_type: servicetype,
            });
          } else if (servicetype === "package") {
            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              show_form: true,
              agent_title: response.data.agent_title,
              super_agent_accountno: response.data.agent_accountno,
              action_type: action_type,
              pageError: "",
              servicesData: [],
              service_type: "",
              portingData: [],
              miscData: [],
              packagesData: response.data.data,
              subscriptio_type: servicetype,
            });
          } else {
            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              show_form: true,
              agent_title: response.data.agent_title,
              super_agent_accountno: response.data.agent_accountno,
              action_type: action_type,
              pageError: "",
              servicesData: [],
              service_type: "",
              portingData: [],
              miscData: [],
              packagesData: [],
              subscriptio_type: "",
            });
          }
        }
      } catch (error) {
        this.setState({
          errorMessage: "There is some error.",
          successMessage: "",
          show_form: false,
          pageError: "",
        });
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
    }
    //}
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleChangeRadio = (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("Main Handler: ", name + " - " + value);
  };

  handleCaptchaChange = (value) => {
    this.captcha_token = value;
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("Main Handler Radio: ", name + " - " + value);
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      country: country,
    });
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
    console.log("STEP: ", this.state.step);
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  gotoLogin = () => {
    window.location.href = `${auth.getResellerRegToken()}/client/login`;
  };

  resendEmail = async () => {
    const { clientRegisteredAccountno, clientRegisteredEmail } = this.state;
   
    this.setState({
      errorMessage: "",
      successMessage: "",
      disableResendBtn: true,
      gifLoader: true,
    });
    const resendEmail = await resendClientVerificationEmail({
      token: process.env.REACT_APP_API_TOKEN,
      api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
      email: clientRegisteredEmail,
      accountno: clientRegisteredAccountno,
    });

    console.log("resend email ", resendEmail.data);
    if (
      resendEmail.data.status === 404 ||
      resendEmail.data.status === 422 ||
      resendEmail.data.errors !== ""
    ) {
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
        show_form: false,
        pageError: "",
      });
    }

    if (resendEmail.data.status === 404) {
      this.setState({
        errorMessage: resendEmail.data.errors,
        successMessage: "",
        show_form: false,
      });
    } else if (
      resendEmail.data.status === 200 &&
      resendEmail.data.message === "Success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage:
          "Email sent to your account Successfully. To complete verification process please go to your inbox and click on  verification link in the email.",
        disableResendBtn: false,
        gifLoader: false,
      });
      setTimeout(() => {
        this.setState({
          successMessage: "",
          errorMessage: "",
        });
      }, 5000);
    }
  };

  formSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    let {
      name,
      email,
      password,
      confirm_password,
      phone,
      company_name,
      title,
      filer_id,
      address,
      address2,
      city,
      state,
      zip,
      no_of_employes,
      service_id,
      super_agent_accountno,
      res_accountno,
      service_type,
      subscriptio_type,
      country,
    } = this.state;
    var is_validated = true;
    this.setState({
      errorMessage: "",
    });
    if (name === "") {
      this.setState({
        errorMessage: "Please enter the full name.",
      });
      is_validated = false;
    } else if (company_name === "") {
      this.setState({
        errorMessage: "Please enter the company name.",
      });
      is_validated = false;
    } else if (email === "") {
      this.setState({
        errorMessage: "Please enter the valid email.",
      });
      is_validated = false;
    } else if (phone === "") {
      this.setState({
        errorMessage: "Please enter the valid phone number.",
      });
      is_validated = false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (password === "") {
      this.setState({
        errorMessage: "Please enter the password.",
      });
      is_validated = false;
    } else if (password.length < 8) {
      this.setState({
        errorMessage: "Use 8 characters or more for your password",
      });
      is_validated = false;
    } else if (confirm_password === "") {
      this.setState({
        errorMessage: "Confirm password is required.",
      });
      is_validated = false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorMessage: "Use 8 characters or more for your confirm password",
      });
      is_validated = false;
    } else if (password !== confirm_password) {
      this.setState({
        errorMessage: "Password and confirm password does not match.",
      });
      is_validated = false;
    } else if (address === "") {
      this.setState({
        errorMessage: "Please enter the Address.",
      });
      is_validated = false;
    } else if (country === "") {
      this.setState({
        errorMessage: "Please select the Country.",
      });
      is_validated = false;
    } else if (city === "") {
      this.setState({
        errorMessage: "Please enter the City.",
      });
      is_validated = false;
    } else if (state === "") {
      this.setState({
        errorMessage: "Please enter the State.",
      });
      is_validated = false;
    } else if (zip === "") {
      
      this.setState({
        errorMessage: "Please enter the Zip.",
      });
      is_validated = false;
      
    } else if (this.captcha_token === "" || this.captcha_token === null) {
      
      this.setState({
        errorMessage: "Invalid captcha.",
      });
      is_validated = false;
    }

    if (is_validated) {
      try {
        this.setState({
          disabled: true,
        });
        
        let completeAddress = address + " " + address2;
        let clientRegisterService = await registerClient({
          name: name,
          email: email,
          password: password,
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          agent_accountno: super_agent_accountno,
          phone: phone,
          comp_name: company_name,
          title: title,
          filer_id: filer_id,
          no_of_employes: no_of_employes,
          service_id: service_id,
          res_accountno: res_accountno,
          service_type: service_type,
          subscriptio_type: subscriptio_type,
          address: completeAddress,
          city: city,
          state: state,
          zip: zip,
          country: country,
        });
        console.log("response.data : ", clientRegisterService.data);
        if (
          clientRegisterService.data.status === 404 ||
          clientRegisterService.data.status === 422 ||
          clientRegisterService.data.errors !== ""
        ) {
          this.setState({
            errorMessage: "Invalid Email OR Password",
            disabled: false,
          });
        }

        if (
          clientRegisterService.data.status === 404 &&
          clientRegisterService.data.message === "Account Already Exists"
        ) {
          this.setState({
            errorMessage: clientRegisterService.data.message,
            disabled: false,
          });
        } else if (
          clientRegisterService.data.status === 200 &&
          clientRegisterService.data.message === "Success"
        ) {
          $("#resendBtn").show();
          $("#signupBtn").hide();
          $("#stepper-create-profile input").val("");
          this.setState({
            successMessage: ` Congrats! Successfully created your profile. Email
                sent to your account. To complete verification
                process please go to your inbox and click on
                verification link in the email.`,
            errorMessage: "",
            fieldsHide: true,
            disabled: false,
            afterRegistration: true,
            clientRegisteredAccountno:
              clientRegisterService.data.data.accountno,
            clientRegisteredEmail: clientRegisterService.data.data.email,
          });
          setTimeout(() => {
            this.setState({
              successMessage: "",
              errorMessage: "",
            });
          }, 5000);
        } else {
          this.setState({
            errorMessage: ` There is some error while creating the account.`,
            successMessage: "",
            fieldsHide: true,
          });
        }
      } catch (error) {
        console.log("catch : ", error);
        this.setState({
          errorMessage: "There is some error while creating the account.",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 8000);
  };

  submitForms = async () => {
    window.scrollTo(0, 0);
    const {
      name,
      company_name,
      email,
      phone,
      password,
      confirm_password,
      address,
      address2,
      city,
      state,
      zip,
    } = this.state;
    var is_validated = true;
    if (name === "") {
      this.setState({
        errorMessage: "Please enter the full name.",
      });
      is_validated = false;
    } else if (company_name === "") {
      this.setState({
        errorMessage: "Please enter the company name.",
      });
      is_validated = false;
    } else if (email === "") {
      this.setState({
        errorMessage: "Please enter the valid email.",
      });
      is_validated = false;
    } else if (phone === "") {
      this.setState({
        errorMessage: "Please enter the valid phone number.",
      });
      is_validated = false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (normalize(phone) === null) {
      this.setState({
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (password === "") {
      this.setState({
        errorMessage: "Please enter the password.",
      });
      is_validated = false;
    } else if (password.length < 8) {
      this.setState({
        errorMessage: "Use 8 characters or more for your password",
      });
      is_validated = false;
    } else if (confirm_password === "") {
      this.setState({
        errorMessage: "Confirm password is required.",
      });
      is_validated = false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorMessage: "Use 8 characters or more for your confirm password",
      });
      is_validated = false;
    } else if (password !== confirm_password) {
      this.setState({
        errorMessage: "Password and confirm password does not match.",
      });
      is_validated = false;
    } else if (address === "") {
      this.setState({
        errorMessage: "Please enter the Address.",
      });
      is_validated = false;
    } else if (city === "") {
      this.setState({
        errorMessage: "Please enter the City.",
      });
      is_validated = false;
    } else if (state === "") {
      this.setState({
        errorMessage: "Please enter the State.",
      });
      is_validated = false;
    } else if (zip === "") {
      this.setState({
        errorMessage: "Please enter the Zip.",
      });
      is_validated = false;
    } else {
      console.log("hello ");
    }
    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 3000);
  };

  downloadCsv = async () => {
    console.log("HERE FILE: ");
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };
  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  render() {
    const { step } = this.state;
    const {
      company_name,
      filer_id,
      password,
      confirm_password,
      name,
      email,
      phone,
      title,
      no_of_employes,
      errorMessage,
      successMessage,
      service_id,
      agent_title,
      res_title,
    } = this.state;
    const values = {
      company_name,
      filer_id,
      password,
      confirm_password,
      name,
      email,
      phone,
      title,
      no_of_employes,
      errorMessage,
      successMessage,
      service_id,
      agent_title,
      res_title,
    };

    return (
      
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-body npc-crypto ui-clean pg-auth">
          <div className="nk-app-root">
            <div className="nk-main ">
              <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content ">
                  <div className="nk-block nk-block-middle nk-auth-body wide-md">
                    {this.state.pageError !== "" ? (
                      <div
                        className="nk-wrap nk-wrap-nosidebar"
                        style={{
                          width: "80%",
                          marginRight: "10%",
                          marginLeft: "10%",
                        }}
                      >
                        <div className="nk-content ">
                          <div className="nk-block nk-block-middle wide-md mx-auto">
                            <div className="nk-block-content nk-error-ld text-center">
                              <img
                                className="nk-error-gfx"
                                src="../../../../assets/images/error-404_1.png"
                                alt=""
                              />
                              <div className="wide-xs mx-auto">
                                <h3 className="nk-error-title">
                                  Oops! Why you’re here?
                                </h3>
                                <p className="nk-error-text">
                                  {this.state.pageError}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="brand-logo pb-4 text-center">
                          {auth.getResellerLogo() !== "" ? (
                            <a
                              href={`${auth.getResellerRegToken()}/client/login`}
                              className="logo-link"
                            >
                              <img
                                className="logo-light logo-img logo-img-lg"
                                src={auth.getResellerLogo()}
                                alt="logo"
                              />
                              <img
                                className="logo-dark logo-img logo-img-lg"
                                src={auth.getResellerLogo()}
                                alt="logo-dark"
                              />
                            </a>
                          ) : (
                            <a
                              href={`${auth.getResellerRegToken()}/client/login`}
                              className="logo-link"
                            >
                              <img
                                className="logo-light logo-img logo-img-lg"
                                src={LOGO}
                                alt="logo"
                              />
                              <img
                                className="logo-dark logo-img logo-img-lg"
                                src={LOGO}
                                alt="logo-dark"
                              />
                            </a>
                          )}
                        </div>
                        <div className="card card-bordered pt-3 ">
                          {this.state.tableLoader === true ? (
                            FormLoader()
                          ) : (
                            <>
                              <div className="card-inner card-inner-lg">
                                <div className="nk-block-head">
                                  <div className="nk-block-head-content">
                                    <HelmetProvider>
                                      <Helmet>
                                        <meta
                                          name="description"
                                          content="Client SignUp"
                                        />
                                        <title>Client SignUp</title>
                                      </Helmet>
                                    </HelmetProvider>
                                    <h4 className="nk-block-title">
                                      {auth.getResellerRegTitle() !== ""
                                        ? auth.getResellerRegTitle()
                                        : "Number.io"}{" "}
                                      Client SignUp
                                    </h4>
                                    <div className="nk-block-des">
                                      <p
                                        style={{
                                          color: "#637381",
                                          marginTop: "25px",
                                        }}
                                      >
                                        {" "}
                                        This portal provides you access to{" "}
                                        {auth.getResellerRegTitle() !== ""
                                          ? auth.getResellerRegTitle()
                                          : "Number.io"}{" "}
                                        service contracts, rates, reports, and
                                        product marketing resources you need to
                                        establish services. Please visit the
                                        main site for other{" "}
                                        {auth.getResellerRegTitle() !== ""
                                          ? auth.getResellerRegTitle()
                                          : "Number.io"}{" "}
                                        Business Units.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert mb-4">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert mb-4">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.afterRegistration === false ? (
                                  <>
                                    <form
                                      method="post"
                                      onSubmit={this.formSubmit}
                                      id="stepper-create-profile"
                                    >
                                      <div className="row">
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-first-name"
                                          >
                                            Full Name
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="name"
                                              name="name"
                                              placeholder="Full Name"
                                              defaultValue={this.state.name}
                                              onChange={this.handleChange}
                                              maxLength={100}
                                              required
                                            />
                                          </div>
                                        </div>

                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-first-name"
                                          >
                                            Company Name
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="company_name"
                                              name="company_name"
                                              placeholder="Company Name"
                                              defaultValue={
                                                this.state.company_name
                                              }
                                              onChange={this.handleChange}
                                              maxLength={100}
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            Email
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="email"
                                              className="form-control"
                                              id="email"
                                              name="email"
                                              defaultValue={this.state.email}
                                              onChange={this.handleChange}
                                              placeholder="info@example.com"
                                              maxLength={100}
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="fv-phone"
                                          >
                                            Phone
                                          </label>

                                          <PhoneInput
                                            required
                                            defaultCountry={"us"}
                                            inputProps={{
                                              name: "phone",
                                              id: "phone",
                                              maxLength: 20,
                                            }}
                                            value={this.state.phone}
                                            onChange={(value) =>
                                              this.handleChange({
                                                target: {
                                                  name: "phone",
                                                  value,
                                                },
                                              })
                                            }
                                            helperText="Enter the phone number here."
                                            label="Phone Number"
                                          />
                                        </div>

                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-name-on-card"
                                          >
                                            Password
                                          </label>
                                          <div className="form-control-wrap">
                                            <a
                                              style={{
                                                cursor: "pointer",
                                                top: "16px",
                                              }}
                                              tabIndex="-1"
                                              onClick={() => {
                                                this.togglePassword();
                                              }}
                                              className="form-icon form-icon-right passcode-switch"
                                              data-target="password"
                                            >
                                              {this.state.passwordShown ? (
                                                <em className="icon ni ni-eye-off"></em>
                                              ) : (
                                                <em className="icon ni ni-eye"></em>
                                              )}
                                            </a>
                                            <input
                                              type={
                                                this.state.passwordShown
                                                  ? "text"
                                                  : "password"
                                              }
                                              minLength="8"
                                              maxLength="50"
                                              className="form-control"
                                              id="password"
                                              name="password"
                                              placeholder="Password"
                                              defaultValue={this.password}
                                              onChange={this.handleChange}
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-card-number"
                                          >
                                            Confirm Password
                                          </label>
                                          <div className="form-control-wrap">
                                            <a
                                              style={{
                                                cursor: "pointer",
                                                top: "16px",
                                              }}
                                              tabIndex="-1"
                                              onClick={() => {
                                                this.toggleConfirmPassword();
                                              }}
                                              className="form-icon form-icon-right passcode-switch"
                                              data-target="password"
                                            >
                                              {this.state
                                                .confirmPasswordShown ? (
                                                <em className="icon ni ni-eye-off"></em>
                                              ) : (
                                                <em className="icon ni ni-eye"></em>
                                              )}
                                            </a>
                                            <input
                                              type={
                                                this.state.confirmPasswordShown
                                                  ? "text"
                                                  : "password"
                                              }
                                              minLength="8"
                                              maxLength="50"
                                              className="form-control"
                                              id="confirm_password"
                                              name="confirm_password"
                                              placeholder="Password"
                                              defaultValue={
                                                this.confirm_password
                                              }
                                              onChange={this.handleChange}
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            Address
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="address"
                                              name="address"
                                              defaultValue={this.state.address}
                                              onChange={this.handleChange}
                                              placeholder="Address "
                                              maxLength={200}
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            Address 2
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="address2"
                                              name="address2"
                                              defaultValue={this.state.address2}
                                              onChange={this.handleChange}
                                              placeholder="Address 2"
                                              maxLength={200}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            Country
                                          </label>
                                          <div className="form-control-wrap">
                                            <Select options={this.countryOptions} value={this.state.country} onChange={this.handleChangeCountry} />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            City
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="city"
                                              name="city"
                                              defaultValue={this.state.city}
                                              onChange={this.handleChange}
                                              placeholder="City"
                                              maxLength={100}
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            State
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="state"
                                              name="state"
                                              defaultValue={this.state.state}
                                              onChange={this.handleChange}
                                              placeholder="State"
                                              maxLength={3}
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            Zip / Postal Code
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="zip"
                                              name="zip"
                                              defaultValue={this.state.zip}
                                              onChange={this.handleChange}
                                              placeholder="Zip / Postal Code"
                                              maxLength={9}
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-12">
                                          <ReCAPTCHA
                                            sitekey={this.site_key}
                                            onChange={this.handleCaptchaChange}
                                            style={{ padding: "0px 0px 15px" }}
                                          />
                                        </div>
                                        <div className="form-group col-lg-12">
                                          <button
                                            className="btn btn-lg btn-block"
                                            disabled={this.state.disabled}
                                            type="submit"
                                            style={{
                                              backgroundColor: THEME_COLOR,
                                              color: "white",
                                            }}
                                            
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                    <div className="form-note-s2 text-center pt-4">
                                      {" "}
                                      Already have an account ?{" "}
                                      <a
                                        href={`${auth.getResellerRegToken()}/client/login`}
                                      >
                                        Sign in instead
                                      </a>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {this.state.gifLoader === true ? (
                                      FormLoader()
                                    ) : (
                                      <div className="gfx w-50 mx-auto text-center">
                                        <img
                                          className="hide_this_img image"
                                          src="../../../assets/images/animation_success.gif"
                                        />
                                      </div>
                                    )}
                                    <div className="row pt-4">
                                      <div className="form-group col-lg-6">
                                        <button
                                          type="button"
                                          className="btn btn-lg btn-block"
                                          onClick={this.gotoLogin}
                                          style={{
                                            backgroundColor: THEME_COLOR,
                                            color: "white",
                                          }}
                                        >
                                          Login
                                        </button>
                                      </div>
                                      <div className="form-group col-lg-6">
                                        <button
                                          className="btn btn-lg btn-block"
                                          onClick={this.resendEmail}
                                          disabled={this.state.disableResendBtn}
                                          id="resendBtn"
                                          style={{
                                            backgroundColor: THEME_COLOR,
                                            color: "white",
                                          }}
                                        >
                                          Resend Email
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="container wide-lg">
                    <div className="row g-3">
                      <div className="col-lg-12">
                        <div className="nk-block-content text-center">
                          <p>
                            &copy; {new Date().getFullYear()}{" "}
                            <a
                              href="https://onboardsoft.com/"
                              style={{ color: "#526484" }}
                            >
                              OnBoardsoft LLC
                            </a>
                            . All Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
